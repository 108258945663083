import { IBasket, IBasketList } from 'types';

import {
  parseAPIBasket,
  parseIBasketPaginatedAPIResponse
} from './BasketServiceHelper';
import { IBasketApi } from './types/IBasketApi';
import { IBasketDiscountPayload } from './types/IBasketDiscountPayload';
import { IBasketPreviewPayload } from './types/IBasketPreviewPayload';
import { IBasketsPaginatedApi } from './types/IBasketsPaginatedApi';
import { execGet, execPost, execDelete } from '../RequestUtils';

const API_URL = `${process.env.REACT_APP_SHOPS_API}`;

export async function getBasket(
  basketId: string,
  locale: string
): Promise<IBasket> {
  const params = new URLSearchParams();
  params.set('locale', locale);
  params.set('expand', 'items');

  const apiResponse = await execGet<IBasketApi>(
    `${API_URL}/baskets/shared/${basketId}?${params.toString()}`
  );
  return parseAPIBasket(apiResponse);
}

export async function getBaskets(
  page: number,
  perPage: number,
  locale: string
): Promise<IBasketList> {
  const params = new URLSearchParams();
  params.set('page', `${page}`);
  params.set('perPage', `${perPage}`);
  params.set('locale', locale);
  params.set('expand', 'items');

  const apiResponse = await execGet<IBasketsPaginatedApi>(
    `${API_URL}/baskets/shared?${params.toString()}`
  );
  return parseIBasketPaginatedAPIResponse(apiResponse);
}

export async function previewBasket(
  data: IBasketPreviewPayload
): Promise<IBasket> {
  const apiBasket = await execPost<IBasketApi>(
    `${API_URL}/baskets/shared/preview`,
    data
  );

  return parseAPIBasket(apiBasket);
}

export async function createBasket(data: {
  readonly shopId: string;
  readonly customerId: string;
  readonly items: ReadonlyArray<{
    readonly articleNumber: string;
    readonly quantity: number;
  }>;
  readonly affiliateDiscount?: ReadonlyArray<IBasketDiscountPayload>;
}): Promise<IBasket> {
  const apiBasket = await execPost<IBasketApi>(
    `${API_URL}/baskets/shared`,
    data
  );

  return parseAPIBasket(apiBasket);
}

export function cancelBasket(basketId: string) {
  return execDelete<void>(`${API_URL}/baskets/shared/${basketId}`);
}
