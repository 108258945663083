import { Pagination } from 'components/pagination';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useState } from 'react';

import { CommitmentListItem } from './CommitmentListItem';
import { useCommitments } from '../../hooks/useCommitments';

const DEFAULT_COMMITMENT_LIST_PAGE_SIZE = 20;

export function CommitmentsList() {
  const { activeLanguage } = useTranslation();
  const [pageDetails, setPageDetails] = useState<{
    readonly page: number;
    readonly perPage: number;
  }>({
    page: 1,
    perPage: DEFAULT_COMMITMENT_LIST_PAGE_SIZE
  });

  const { data: commitmentList } = useCommitments(
    pageDetails.page,
    pageDetails.perPage,
    activeLanguage
  );

  return (
    <>
      <div className="flex justify-end mb-sm">
        <Pagination
          currentPage={pageDetails.page}
          onPageChange={p =>
            setPageDetails({
              page: p,
              perPage: DEFAULT_COMMITMENT_LIST_PAGE_SIZE
            })
          }
          totalPages={commitmentList?.paginationInfo.totalPages ?? 0}
          siblingCount={2}
        />
      </div>
      {!_.isUndefined(commitmentList) &&
        commitmentList.commitments.length > 0 && (
          <ul className="bg-white shadow-md rounded-md">
            {commitmentList.commitments.map(commitment => {
              return <CommitmentListItem {...commitment} key={commitment.id} />;
            })}
          </ul>
        )}
    </>
  );
}
