import PMIWeb from '@pmi.web/ui';
import { SwitchButton } from 'components/buttons';
import { PII, usePII } from 'features/pii';
import { useBoolean } from 'hooks';
import { CountryFlag } from 'libs/icons';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { IoShareOutline } from 'react-icons/io5';
import { PromocodeStatus } from 'types/promocodes';

import { PromocodeLinkShare } from './PromocodeLinkShare';
import './PromocodeTicket.scss';
import { useChangePromocodeStatus } from '../hooks';
import { isStatusActive } from '../utils';

interface IPromocodeTicket {
  readonly id: string;
  readonly code: string;
  readonly url: string;
  readonly countryCode: string;
  readonly status: PromocodeStatus;
}

export function PromocodeTicket(props: IPromocodeTicket) {
  const { t } = useTranslation();
  const { pii } = usePII();

  const {
    changePromocodeStatus,
    isPending: isStatusChangePending,
    error: statusChangeError,
    isError: statusChangeHasError
  } = useChangePromocodeStatus();

  const [
    showAppSharePopup,
    { setFalse: closeAppSharePopup, setTrue: openAppSharePopup }
  ] = useBoolean(false);

  useEffect(() => {
    if (statusChangeHasError) {
      triggerErrorToast('Couldn’t update promo code status.');
    }
  }, [statusChangeError?.message, statusChangeHasError]);

  const handleStatusSwitch = (active: boolean) => {
    changePromocodeStatus({
      id: props.id,
      status: active ? PromocodeStatus.Active : PromocodeStatus.Inactive
    });
  };

  const statusActive = isStatusActive(props.status);

  if (statusActive === null) {
    return null;
  }

  return (
    <div>
      <div className={`inactive-bar ${!statusActive ? 'show' : ''}`}>
        <p className="uppercase align-middle text-center text-error font-bold">
          {t('deactivated')}
        </p>
      </div>

      <div className="ticket">
        <div
          className={`bg-white p-lg shadow-md overflow-hidden flex items-center ${
            !statusActive || 'rounded-md'
          }`}
        >
          <div className="flex-1">
            <div className="flex gap-2 items-center justify-between">
              <h1 className="max-w-32 text-2xl font-bold text-ellipsis overflow-hidden whitespace-nowrap">
                <PII type="tpid" value={props.code} />
              </h1>

              <PMIWeb.Components.PrimaryButton
                disabled={!statusActive}
                onClick={() => {
                  if ('share' in navigator) {
                    try {
                      navigator.share({
                        url: props.url
                      });
                    } catch (err) {
                      // If user cancels, an error is thrown
                    }
                  } else {
                    openAppSharePopup();
                  }
                }}
              >
                <IoShareOutline size={20} />
                {t('Share')}
              </PMIWeb.Components.PrimaryButton>
            </div>

            <div className="flex gap-2 items-center justify-between mt-xl">
              <div>
                <CountryFlag
                  variant="rounded"
                  countryCode={pii(props.countryCode, 'country')}
                />
              </div>

              <div>
                <SwitchButton
                  disabled={isStatusChangePending}
                  title={statusActive ? 'On' : 'Off'}
                  isOn={statusActive}
                  onClick={() => handleStatusSwitch(!statusActive)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <PromocodeLinkShare
        open={showAppSharePopup}
        onClose={closeAppSharePopup}
        url={props.url}
      />
    </div>
  );
}
