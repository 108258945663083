import { useUser } from 'hooks';
import { useTranslation } from 'libs/translations';
import { IBasket } from 'types';

import { BasketCard } from './BasketCard';
import { BasketOwner } from '../basket/BasketOwner';

export function PaymentPendingBasketCard(basket: IBasket) {
  const { t } = useTranslation();

  const { data: userData } = useUser(basket.basket.customerId);
  const wasProspect =
    (userData?.createdAt.getTime() ?? 0) > basket.basket.createdOn.getTime();

  return (
    <BasketCard status={basket.basket.status}>
      <div className="flex flex-row items-center gap-sm flex-wrap">
        <BasketOwner
          basketOwnerId={basket.basket.customerId}
          basketCreadtedOn={basket.basket.createdOn}
        />
        {wasProspect && (
          <p className="pmi-caption text-silver font-bold bg-lightGray px-xs py-xxs rounded-full">
            {t('Registration is in progress')}
          </p>
        )}
      </div>
      <p className="pmi-caption mt-xs text-silver font-bold">
        {t(
          "You're almost there! Make sure your customer completes the purchase so they can enjoy the benefits."
        )}
      </p>
    </BasketCard>
  );
}
