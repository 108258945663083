import { SupportedLanguage } from '@pmi.web/localization';
import { useQuery } from '@tanstack/react-query';

import { fetchPromocodeFAQsStory } from './PromocodeFAQService';

const FAQS_SLUG = 'promo-code-faq';

const PROMOCODE_FAQS_QUERY_KEY = 'pmi.web.office__promocode_faqs';
const TTL = 900000; // 15 minutes in milliseconds

export function usePromocodeFAQs(language: SupportedLanguage) {
  const query = useQuery({
    queryKey: [PROMOCODE_FAQS_QUERY_KEY, FAQS_SLUG, language],
    queryFn: () => fetchPromocodeFAQsStory(FAQS_SLUG, language),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
