import { Icons } from 'libs/icons';
import { ReactNode } from 'react';
import { BasketStatus } from 'types';

import { BasketStatusBadge } from '../basket/BasketStatusBadge';

export function BasketCard(props: {
  readonly children: ReactNode;
  readonly status: BasketStatus;
}) {
  return (
    <>
      <BasketStatusBadge status={props.status} small />
      <div>{props.children}</div>
      <Icons.Common.ChevronRight size={20} className="text-silver" />
    </>
  );
}
