import { localizedCountryName } from '@pmi.web/countries';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';

interface ICountryFlagProps {
  readonly countryCode: string | undefined;
  readonly small?: boolean;
  readonly renderLocalizedCountryName?: boolean;
  readonly variant?: 'rounded' | 'square';
}

/**
 * Wrapper component using https://flagpedia.net/download/api to render country
 * flags.
 * @param countryCode the country code representing the flag
 * @param renderLocalizedCountryName if true, the country name in the correct locale
 * will be rendered in front of the flag
 * @returns
 */
export function CountryFlag(props: ICountryFlagProps) {
  const { activeLanguage } = useTranslation();

  if (
    _.isUndefined(props.countryCode) ||
    _.isNull(props.countryCode) ||
    props.countryCode === ''
  ) {
    return null;
  }

  const sanitizedCountryCode = props.countryCode?.toLowerCase().substring(0, 2);

  const flagSizeClass =
    props.variant === 'rounded'
      ? props.small
        ? 'w-[14px] h-[14px] rounded-full'
        : 'w-[24px] h-[24px] rounded-full'
      : props.small
      ? 'h-[14px]'
      : 'h-[20px]';

  return (
    <span className="flex flex-row items-center">
      <span className="h-fit w-fit shrink-0">
        <picture>
          <source
            type="image/webp"
            srcSet={`https://flagcdn.com/h20/${sanitizedCountryCode}.webp, https://flagcdn.com/h40/${sanitizedCountryCode}.webp 2x, https://flagcdn.com/h60/${sanitizedCountryCode}.webp 3x`}
          />
          <source
            type="image/png"
            srcSet={`https://flagcdn.com/h20/${sanitizedCountryCode}.png, https://flagcdn.com/h40/${sanitizedCountryCode}.png 2x, https://flagcdn.com/h60/${sanitizedCountryCode}.png 3x`}
          />
          <img
            src={`https://flagcdn.com/h20/${sanitizedCountryCode}.png`}
            alt={sanitizedCountryCode}
            className={`border border-[#e5e5e5] ${flagSizeClass}`}
          />
        </picture>
      </span>
      {props.renderLocalizedCountryName && (
        <span className="ml-xxs">
          {localizedCountryName(sanitizedCountryCode, activeLanguage)}
        </span>
      )}
    </span>
  );
}
