import { SupportedLanguage } from '@pmi.web/localization';
import PMIWeb from '@pmi.web/ui';
import { useQuery } from '@tanstack/react-query';
import { SwitchButton } from 'components/buttons';
import { LoadingSpinner } from 'components/spinners';
import { FormattedUnitValue } from 'components/text';
import { useGlobalConfigsContext } from 'contexts';
import { useCreateBasket } from 'features/baskets';
import { BasketOwner } from 'features/virtual-warehouse';
import { useBoolean } from 'hooks';
import { replaceStringWithComponent } from 'libs/components';
import { Timestamp } from 'libs/time';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { Fragment, useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { IoStorefront } from 'react-icons/io5';
import { previewBasket } from 'services';
import { execGet } from 'services/RequestUtils';
import { IBasketDiscountPayload } from 'services/shared-baskets/types/IBasketDiscountPayload';

import { QuantityController } from './components/basket-creation/wizard/QuantityController';
import { useNewBasketContext } from './components/basket-creation-wizard/NewBasketContext';

export function WizardOwnerStep() {
  const { userId, prospectId } = useNewBasketContext();
  const basketOwnerId = userId ?? prospectId;
  return basketOwnerId ? (
    <BasketOwner
      basketOwnerId={basketOwnerId}
      basketCreadtedOn={Timestamp.now()}
    />
  ) : null;
}

export function useBasketPreviewer(
  shopId: string,
  customerId: string,
  items: ReadonlyArray<{
    readonly articleNumber: string;
    readonly quantity: number;
  }>,
  affiliateDiscount?: ReadonlyArray<IBasketDiscountPayload>
) {
  const query = useQuery({
    queryKey: [
      'pmi.web.office__basketpreview',
      shopId,
      customerId,
      items,
      affiliateDiscount
    ],
    queryFn: () =>
      previewBasket({
        shopId,
        customerId,
        items,
        affiliateDiscount
      }),
    staleTime: 0,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}

const FIVE_PERCENT_DISCOUNT_SETTINGS = {
  id: 'customerDiscount',
  type: 'percentage',
  value: 5
};

export function WizardContentStep() {
  const { t } = useTranslation();
  const { promoShop } = useGlobalConfigsContext();
  const { userId, prospectId, articles, dispatchStepEvent } =
    useNewBasketContext();

  const { createBasket, isPending, data, isError, error } = useCreateBasket();
  const [isDiscountActive, { toggle: toogleDiscount }] = useBoolean(false);
  const { customerId, basketItems } = useMemo(() => {
    const customerId = userId ?? prospectId ?? undefined;
    const basketItems = Array.from(articles).map(a => ({
      articleNumber: a[0] as string,
      quantity: a[1] as number
    }));

    return {
      customerId,
      basketItems
    };
  }, [articles, prospectId, userId]);

  const { shopId, discounts } = useMemo(() => {
    return {
      shopId: promoShop?.id,
      discounts: isDiscountActive ? [FIVE_PERCENT_DISCOUNT_SETTINGS] : undefined
    };
  }, [isDiscountActive, promoShop?.id]);

  const handleConfirm = () => {
    if (shopId && customerId) {
      createBasket({
        shopId,
        customerId,
        items: basketItems,
        affiliateDiscount: discounts
      });
    }
  };

  useEffect(() => {
    if (data && data.checkoutUrl) {
      dispatchStepEvent({
        type: 'set-basket-id',
        value: data.checkoutUrl
      });
    }
  }, [data, dispatchStepEvent]);

  useEffect(() => {
    // TODO: improve
    if (isError) {
      triggerErrorToast(error?.message ?? '');
    }
  }, [error?.message, isError]);

  if (basketItems.length === 0) {
    return null;
  }

  return (
    <>
      {(userId || prospectId) && basketItems.length > 0 && (
        <section className="divide-y divide-solid divide-primary">
          {promoShop?.isAffiliateDiscountEnabled && (
            <div className="flex justify-between items-center py-sm">
              <p>
                {replaceStringWithComponent(
                  t('Offer {{discountFormattedValue}} discount'),
                  '{{discountFormattedValue}}',
                  () => (
                    <FormattedUnitValue
                      unit={FIVE_PERCENT_DISCOUNT_SETTINGS.type}
                      value={FIVE_PERCENT_DISCOUNT_SETTINGS.value}
                    />
                  )
                ).map((elem, index) => (
                  <Fragment key={index}>{elem}</Fragment>
                ))}
              </p>
              <SwitchButton isOn={isDiscountActive} onClick={toogleDiscount} />
            </div>
          )}

          {customerId && shopId && (
            <RenderBasketTotal
              basketItems={basketItems}
              discounts={discounts}
              shopId={shopId}
              userId={customerId}
            />
          )}
        </section>
      )}

      <div className="flex justify-end">
        <PMIWeb.Components.PrimaryButton
          onClick={handleConfirm}
          disabled={
            isPending || (!userId && !prospectId) || articles.size === 0
          }
        >
          {t('Create basket')}
          {isPending && <LoadingSpinner tinySize className="text-white" />}
        </PMIWeb.Components.PrimaryButton>
      </div>

      {isPending && (
        <Fragment>
          <div className="fixed inset-0 z-30 flex items-center justify-center bg-secondary opacity-80"></div>
          <div className="fixed inset-0 z-30 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        </Fragment>
      )}
    </>
  );
}

export function ProductsInBasketSummary({
  allowEditing = false,
  small = false
}: {
  readonly allowEditing?: boolean;
  readonly small?: boolean;
}) {
  const { articles } = useNewBasketContext();
  const basketItems = Array.from(articles).map(a => ({
    articleNumber: a[0] as string,
    quantity: a[1] as number
  }));

  return (
    <ul className="w-full grid grid-cols-[56px_1fr_auto] gap-sm">
      {basketItems.map(i => (
        <li key={i.articleNumber} className="contents">
          <ProductSummaryItem
            articleNumber={i.articleNumber}
            quantity={i.quantity}
            allowEditing={allowEditing}
            small={small}
          />
        </li>
      ))}
    </ul>
  );
}

function ProductSummaryItem({
  articleNumber,
  quantity,
  allowEditing,
  small
}: {
  readonly articleNumber: string;
  readonly quantity: number;
  readonly allowEditing?: boolean;
  readonly small?: boolean;
}) {
  const { t } = useTranslation();
  const { activeLanguage } = useTranslation();
  const { promoShop } = useGlobalConfigsContext();
  const shopId = promoShop?.id ?? '';
  const { data, isLoading } = useProduct(articleNumber, shopId, activeLanguage);

  const { name, imgSrc, currency, price } = useMemo(() => {
    let name;
    let imgSrc;
    let currency;
    let price;

    if (data) {
      name = data.product.details.attributes?.name?.value ?? articleNumber;
      imgSrc = data.product.details.media.find(
        (m: any) => m.type === 'image' && !_.isUndefined(m.url)
      )?.url;
      currency = data.product.details.currencyCode;
      price = data.product.details.finalPrice;
    }

    return {
      name,
      imgSrc,
      currency,
      price
    };
  }, [articleNumber, data]);

  return (
    <>
      {imgSrc ? (
        <object
          data={imgSrc}
          type="image/png"
          className="bg-lightGray h-[56px] w-[56px] row-span-2"
        >
          <IoStorefront size={24} className="text-silver m-auto h-full" />
        </object>
      ) : (
        <span className="bg-lightGray h-[56px] w-[56px] row-span-2">
          <IoStorefront size={24} className="text-silver m-auto h-full" />
        </span>
      )}
      {!isLoading ? (
        <div className="flex flex-col items-start justify-between">
          <span className="line-clamp-1">{name}</span>
          {allowEditing ? null : ( // <QuantityController articleNumber={articleNumber} small={small} />
            <span className="pmi-caption">
              {t('Quantity: {{quantity}}', { quantity })}
            </span>
          )}
        </div>
      ) : (
        <div className="bg-lightGray w-full rounded-md min-h-[16px] animate-pulse row-span-2"></div>
      )}
      {!isLoading ? (
        <span className="text-right">
          <FormattedUnitValue value={quantity * price} unit={currency} />
        </span>
      ) : (
        <div className="bg-lightGray w-full rounded-md min-h-[16px] animate-pulse row-span-2"></div>
      )}
      {!isLoading && (
        <div className="col-span-2 flex justify-end -translate-y-xs">
          {allowEditing && (
            <QuantityController articleNumber={articleNumber} small={small} />
          )}
        </div>
      )}
    </>
  );
}

function useProduct(
  articleNumber: string,
  shopId: string,
  language: SupportedLanguage
) {
  const query = useQuery({
    queryKey: ['ProductItem', articleNumber, shopId, language],
    queryFn: () => getProductDetails(articleNumber, shopId, language),
    staleTime: 0,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}

function getProductDetails(
  articleNumber: string,
  shopId: string,
  language: SupportedLanguage
) {
  return execGet<any>(
    `${process.env.REACT_APP_SHOPS_API}/${shopId}/products/${articleNumber}?locale=${language}`
  );
}

function RenderBasketTotal({
  shopId,
  userId,
  basketItems,
  discounts
}: {
  readonly shopId: string;
  readonly userId: string;
  readonly basketItems: { articleNumber: string; quantity: number }[];
  readonly discounts?: {
    id: string;
    type: string;
    value: number;
  }[];
}) {
  const { t } = useTranslation();
  const { data: basketPreview, isLoading } = useBasketPreviewer(
    shopId,
    userId,
    basketItems,
    discounts
  );

  const { currency, price, basePrice } = useMemo(() => {
    const price = basketPreview?.basket.totals.totalGross;
    const basePrice =
      basketPreview?.basket.totals.itemsTotalGrossExcludingDiscount;

    return {
      currency: basketPreview?.basket.totals.currencyCode,
      price,
      basePrice
    };
  }, [basketPreview]);

  return (
    <div className="flex flex-row gap-sm justify-between py-sm">
      <p>{t('Total')}</p>
      <div className="text-right">
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner smallSize />
          </div>
        ) : (
          !_.isUndefined(currency) &&
          !_.isUndefined(price) &&
          !_.isUndefined(basePrice) && (
            <div>
              {discounts && (
                <span className="text-silver text-sm line-through mr-xxs">
                  <FormattedUnitValue unit={currency} value={basePrice} />
                </span>
              )}
              <p className="text-xl font-bold">
                <FormattedUnitValue unit={currency} value={price} />
              </p>
            </div>
          )
        )}
        <p className="text-silver pmi-caption">
          + {t('Shipping if applicable')}
        </p>
      </div>
    </div>
  );
}
