import { SupportedLanguage } from '@pmi.web/localization';

export const LANGUAGE_COUNTRY_NAMES_LOCALIZED = {
  [SupportedLanguage.Bulgarian]: 'България',
  [SupportedLanguage.Czech]: 'Česko',
  [SupportedLanguage.German]: 'Deutschland',
  [SupportedLanguage.English]: 'United States of America',
  [SupportedLanguage.Spanish]: 'España',
  [SupportedLanguage.Finnish]: 'Suomi',
  [SupportedLanguage.French]: 'France',
  [SupportedLanguage.Hungarian]: 'Magyarország',
  [SupportedLanguage.Indonesian]: 'Indonesia',
  [SupportedLanguage.Icelandic]: undefined,
  [SupportedLanguage.Italian]: 'Italia',
  [SupportedLanguage.Japanese]: '日本',
  [SupportedLanguage.Korean]: '대한민국',
  [SupportedLanguage.Norwegian]: 'Norge',
  [SupportedLanguage.Dutch]: 'Nederland',
  [SupportedLanguage.Polish]: 'Polska',
  [SupportedLanguage.Portuguese]: 'Portugal',
  [SupportedLanguage.Russian]: 'Россия',
  [SupportedLanguage.Swedish]: 'Sverige',
  [SupportedLanguage.Thai]: undefined,
  [SupportedLanguage.Turkish]: undefined,
  [SupportedLanguage.Ukrainian]: undefined,
  [SupportedLanguage.ChineseSimplified]: '中国',
  [SupportedLanguage.ChineseTraditional]: '繁體',
  [SupportedLanguage.Vietnamese]: undefined,
  [SupportedLanguage.Romanian]: 'România'
};
