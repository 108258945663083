import { useGlobalConfigsContext } from 'contexts';
import { PII, usePII } from 'features/pii';
import { FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import CountUp from 'react-countup';
import { IUserCarBonusData } from 'types';
import { formatCurrencyLocalized } from 'utils';

import { WidgetCarBonusDetails } from './WidgetCarBonusDetails';
import { Widget } from '../Widget';

export function WidgetCarBonus(props: IUserCarBonusData) {
  const { t, activeLanguage } = useTranslation();
  const {
    app: {
      office: { enableAnimations }
    }
  } = useGlobalConfigsContext();
  const { isPrivacyGuardEnabled } = usePII();

  const content = useMemo(() => {
    if (
      _.isUndefined(props.carBonusCurrency) ||
      _.isUndefined(props.carBonusHistory) ||
      _.isUndefined(props.carBonusTotal)
    ) {
      return null;
    }

    return (
      <h2 className="font-app font-bold text-[18px] leading-[24px] sm:text-[24px] sm:leading-[36px]">
        {enableAnimations && !isPrivacyGuardEnabled ? (
          <CountUp
            end={props.carBonusTotal}
            duration={2}
            formattingFn={n =>
              formatCurrencyLocalized(
                activeLanguage,
                n,
                props.carBonusCurrency as string
              )
            }
          />
        ) : (
          <PII
            value={formatCurrencyLocalized(
              activeLanguage,
              props.carBonusTotal,
              props.carBonusCurrency
            )}
            type="currency"
          />
        )}
      </h2>
    );
  }, [
    props.carBonusCurrency,
    props.carBonusHistory,
    props.carBonusTotal,
    enableAnimations,
    isPrivacyGuardEnabled,
    activeLanguage
  ]);

  if (
    _.isUndefined(props.carBonusCurrency) ||
    _.isUndefined(props.carBonusHistory) ||
    _.isUndefined(props.carBonusTotal)
  ) {
    throw new Error('Car bonus is missing and cannot be rendered');
  }

  return (
    <Widget
      traceId="CarBonus"
      title={t('Car Bonus')}
      detailedViewButtonLabel={t('History')}
      tooltip={t(
        'Savings towards a PM Company Car. The money can be used towards the down payment on a PM Car.'
      )}
      iconName={FeatureIconName.CarBonus}
      content={content}
      detailedView={
        <WidgetCarBonusDetails
          carBonusCurrency={props.carBonusCurrency}
          carBonusHistory={props.carBonusHistory}
          carBonusTotal={props.carBonusTotal}
        />
      }
    />
  );
}
