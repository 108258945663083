import { IIconProps } from '../types/IIconProps';

export function ArrowRightIcon(props: IIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={props.size}
      height={props.size}
      className={props.className}
    >
      <g fill="currentColor">
        <path
          d="M8.809,2.107A.5.5,0,0,0,8,2.5V5H5A1,1,0,0,0,4,6v4a1,1,0,0,0,1,1H8v2.5a.5.5,0,0,0,.809.393l7-5.5a.518.518,0,0,0,0-.786Z"
          fill="currentColor"
        />
        <path d="M1,5A1,1,0,0,0,0,6v4a1,1,0,0,0,2,0V6A1,1,0,0,0,1,5Z" />
      </g>
    </svg>
  );
}
