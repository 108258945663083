import { BasketStatus, IBasket } from 'types';

import { BasketStatusBadge } from './BasketStatusBadge';

export function BasketHeader(basket: IBasket) {
  return (
    <div
      className={`${
        basket.basket.status === BasketStatus.Open
          ? 'bg-active'
          : basket.basket.status === BasketStatus.PaymentAuthorized
          ? 'bg-success'
          : basket.basket.status === BasketStatus.PaymentPending
          ? 'bg-warn'
          : 'bg-lightGray'
      } px-sm md:px-md py-md md:py-lg flex justify-center`}
    >
      <BasketStatusBadge status={basket.basket.status} />
    </div>
  );
}
