import PMIWeb from '@pmi.web/ui';
import { BottomSheetContainer } from 'components/modals';
import { PromoVideo } from 'features/virtual-warehouse/components/promo-video/PromoVideo';
import { useTranslation } from 'libs/translations';
import { useReducer } from 'react';

import { FAQStoryblokContent } from './FAQStoryblokContent';

const defaultState: FAQButtonState = {
  isFAQOpen: false,
  isPromoVideoOpen: false
};

export function FAQButton() {
  const { t } = useTranslation();
  const [state, dispatchStateEvent] = useReducer(reducer, defaultState);

  return (
    <>
      <button
        className="text-center rounded-full text-white p-sm bg-primary w-[52px] h-[52px] text-[22px] shadow-md"
        onClick={() => dispatchStateEvent({ type: 'open-faq' })}
      >
        ?
      </button>
      <BottomSheetContainer
        open={state.isFAQOpen}
        onDismiss={() => dispatchStateEvent({ type: 'close-faq' })}
      >
        <h1>{t("FAQ's")}</h1>
        <FAQStoryblokContent />
        <div className="mt-md flex justify-center">
          <PMIWeb.Components.PrimaryButton
            onClick={() => dispatchStateEvent({ type: 'open-video' })}
          >
            {t('Watch a Short Video')}
          </PMIWeb.Components.PrimaryButton>
        </div>
      </BottomSheetContainer>
      <PromoVideo
        open={state.isPromoVideoOpen}
        onDismiss={() => dispatchStateEvent({ type: 'close-video' })}
      />
    </>
  );
}

interface FAQButtonState {
  readonly isFAQOpen: boolean;
  readonly isPromoVideoOpen: boolean;
}

type FAQButtonStateAction =
  | { type: 'open-faq' }
  | { type: 'open-video' }
  | { type: 'close-faq' }
  | { type: 'close-video' };

function reducer(
  state: FAQButtonState,
  action: FAQButtonStateAction
): FAQButtonState {
  if (action.type === 'open-faq') {
    return {
      isFAQOpen: true,
      isPromoVideoOpen: false
    };
  }

  if (action.type === 'close-faq') {
    return {
      isFAQOpen: false,
      isPromoVideoOpen: false
    };
  }

  if (action.type === 'open-video') {
    return {
      isFAQOpen: false,
      isPromoVideoOpen: true
    };
  }

  if (action.type === 'close-video') {
    return {
      isFAQOpen: true,
      isPromoVideoOpen: false
    };
  }

  return state;
}
