import { QualificationBadge } from 'components/badges';
import { QUALIFICATIONS, QualificationLevel } from 'contants';
import { useFilterPeriod } from 'features/analytics/hooks/useFilterPeriod';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { Fragment } from 'react/jsx-runtime';
import { useFormContext } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { Trans } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { IoChevronDown } from 'react-icons/io5';
import { parseQualificationLevel } from 'utils';

import { useQualificationFilter } from './useQualificationFilter';

export function MinimumQualificationFilter() {
  const { activeLanguage } = useTranslation();
  const { register } = useFormContext();
  const { qualiLevelFilterFrom, qualiLevelOptionsFrom } =
    useQualificationFilter();
  const currentPeriod = useFilterPeriod();

  return (
    <label htmlFor="byQualificationFrom">
      <Trans
        i18nKey={'analytics.quali-level-from'}
        values={{
          period: currentPeriod.toLocaleString(activeLanguage)
        }}
        components={{ bold: <strong /> }}
        defaults="Minimum qualification level in <bold>{{period}}</bold>"
      />
      <div className="select-wrapper">
        {_.isUndefined(qualiLevelFilterFrom) ? (
          <p className="flex-grow">--</p>
        ) : (
          <Fragment>
            <QualificationBadge
              hideDetails
              qualification={
                parseQualificationLevel(
                  qualiLevelFilterFrom
                ) as QualificationLevel
              }
            />
            <p className="flex-grow">
              {
                QUALIFICATIONS[
                  parseQualificationLevel(
                    qualiLevelFilterFrom
                  ) as QualificationLevel
                ].name
              }
            </p>
          </Fragment>
        )}
        <IoChevronDown size={16} />
        <select
          {...register('byQualificationFrom', {
            valueAsNumber: true
          })}
        >
          <option value="">--</option>
          {qualiLevelOptionsFrom.map(qualificationLevel => (
            <option
              key={qualificationLevel}
              value={QUALIFICATIONS[qualificationLevel].numericValue}
            >
              {QUALIFICATIONS[qualificationLevel].name}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
}
