import PMIWeb from '@pmi.web/ui';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { FaCirclePlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import './CreateNewBasketButton.scss';

export function CreateNewBasketButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PMIWeb.Components.PrimaryButton
      onClick={() => {
        navigate('/sales/createbasket');
      }}
    >
      <FaCirclePlus size={16} />
      {t('New Basket')}
    </PMIWeb.Components.PrimaryButton>
  );
}
