import { useQuery } from '@tanstack/react-query';
import { IconButton } from 'components/buttons';
import { UserHeader } from 'components/headers';
import { Pagination } from 'components/pagination';
import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import { IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { ReactNode, useEffect, useMemo, useReducer } from 'react';
import { getEvent, getEventOrders, getFirstlineTickets } from 'services';
import AuthenticationService from 'services/AuthenticationService';
import { IUserProfileSimplified, IUserProfile, IFirstlineTicket } from 'types';

import './DownlineEventTicketsExplorer.scss';
import { NoTicket } from './NoTicket';
import { TicketPurchaseDate } from './TicketPurchaseDate';
import { TicketScanned } from './TicketScanned';
import { TicketType } from './TicketType';

interface IDownlineEventTicketsExplorerProps {
  readonly eventId: string;
}

const DEFAULT_PAGE_SIZE = 10;
const DOWNLINE_EVENT_TICKETS_QUERY_KEY =
  'pmi.web.office__event_tickets_downline';
const TTL = 900000; // 15 minutes in milliseconds

interface DownlineEventTicketsExplorerState {
  readonly currentPage: number;
  readonly selectedUsersPath: IFirstlineTicket[];
  readonly selectedUser: IFirstlineTicket | undefined;
}

const DEFAULT_STATE: DownlineEventTicketsExplorerState = {
  selectedUser: undefined,
  currentPage: 1,
  selectedUsersPath: []
};

type DownlineEventTicketsExplorerAction =
  | {
      type: 'update-path';
      value: IFirstlineTicket;
    }
  | {
      type: 'reset-path';
      value: IFirstlineTicket;
    }
  | {
      type: 'change-page';
      value: number;
    };

function reducer(
  state: DownlineEventTicketsExplorerState,
  action: DownlineEventTicketsExplorerAction
): DownlineEventTicketsExplorerState {
  if (action.type === 'update-path') {
    let selectedUsersPath = [];
    const userIdIndex = state.selectedUsersPath.findIndex(
      u => u.customer.userId === action.value.customer.userId
    );

    if (userIdIndex === -1) {
      selectedUsersPath = [...state.selectedUsersPath, action.value];
    } else {
      selectedUsersPath = state.selectedUsersPath.slice(0, userIdIndex + 1);
    }

    const selectedUser =
      selectedUsersPath.length > 0
        ? selectedUsersPath[selectedUsersPath.length - 1]
        : undefined;

    return {
      selectedUser,
      selectedUsersPath,
      currentPage: DEFAULT_STATE.currentPage
    };
  }

  if (action.type === 'change-page') {
    return {
      ...state,
      selectedUsersPath: state.selectedUsersPath,
      currentPage: action.value
    };
  }

  if (action.type === 'reset-path') {
    return {
      selectedUser: action.value,
      selectedUsersPath: [action.value],
      currentPage: DEFAULT_STATE.currentPage
    };
  }

  return state;
}

export function DownlineEventTicketsExplorer(
  props: IDownlineEventTicketsExplorerProps
) {
  const { t } = useTranslation();

  const [state, updateState] = useReducer(reducer, DEFAULT_STATE);

  const {
    data: currentPageResults,
    isLoading,
    error: queryError
  } = useQuery({
    queryKey: [
      DOWNLINE_EVENT_TICKETS_QUERY_KEY,
      props.eventId,
      state.selectedUser?.customer.userId,
      state.currentPage
    ],
    queryFn: () => {
      if (_.isUndefined(state.selectedUser)) {
        return Promise.resolve(undefined);
      }

      return getFirstlineTickets(
        props.eventId,
        state.selectedUser?.customer.userId,
        {
          pageNumber: state.currentPage,
          pageSize: DEFAULT_PAGE_SIZE
        }
      );
    },
    staleTime: TTL,
    retry: false
  });
  const error = queryError ?? undefined;

  const totalPages = useMemo(() => {
    return currentPageResults?.paginationInfo.totalPages ?? 0;
  }, [currentPageResults]);

  const onPageChange = (page: number) => {
    updateState({ type: 'change-page', value: page });
  };

  const browseUser = (user: IFirstlineTicket) => {
    if (
      state.selectedUsersPath.length > 0 &&
      user.customer.userId ===
        state.selectedUsersPath[state.selectedUsersPath.length - 1].customer
          .userId
    ) {
      return;
    }

    updateState({
      type: 'update-path',
      value: user
    });
  };

  useEffect(() => {
    Promise.all([getEvent(props.eventId), getEventOrders(props.eventId)]).then(
      ([eventConfig, orders]) => {
        const signedInUserTicketOrder = orders.find(order =>
          order.tickets
            .map(t => `${t.attendeeType}_${t.tpId}`)
            .includes(`tp_${AuthenticationService.user.userId}`)
        );
        const signedInUserTicket = signedInUserTicketOrder?.tickets.find(
          t =>
            t.tpId === AuthenticationService.user.userId &&
            t.attendeeType === 'tp'
        );

        const ticketSettings = signedInUserTicket
          ? eventConfig.ticketSettings.items.find(
              ticket =>
                ticket.articleNumber === signedInUserTicket.articleNumber
            )
          : undefined;

        updateState({
          type: 'reset-path',
          value: {
            customer: AuthenticationService.user,
            hasTicket: !_.isUndefined(signedInUserTicketOrder),
            ticket:
              !_.isUndefined(signedInUserTicketOrder) &&
              !_.isUndefined(ticketSettings)
                ? {
                    isScanned: false,
                    purchaseDate: signedInUserTicketOrder.createdOn,
                    ticketName: ticketSettings.name,
                    ticketType: ticketSettings.type
                  }
                : undefined
          }
        });
      }
    );
  }, [props.eventId]);

  return (
    <section>
      <h2 className="text-primary mt-md md:mt-lg mb-sm">
        {t("My Team's tickets")}
      </h2>

      <DownlineEventTicketList
        items={state.selectedUsersPath}
        onClick={u => browseUser(u)}
      />

      <div className="flex justify-end mt-md mx-xs md:mx-sm lg:mx-md">
        <Pagination
          currentPage={state.currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>

      <section className="relative mt-xs mx-xs md:mx-sm lg:mx-md">
        {isLoading && (
          <div className="absolute inset-0 pmi-glass-white flex items-center justify-center">
            <LoadingSpinner smallSize />
          </div>
        )}

        {!_.isUndefined(error) && (
          <Disclaimer
            type="error"
            message={t(
              "Something went wrong. We could not fetch your team's tickets right now. Try again later or contact our support team."
            )}
          />
        )}

        {!_.isUndefined(currentPageResults) &&
          currentPageResults.paginationInfo.totalRecords > 0 && (
            <DownlineEventTicketList
              items={currentPageResults.results}
              onClick={u => browseUser(u)}
            />
          )}
      </section>
    </section>
  );
}

function DownlineEventTicketList(props: {
  readonly items: ReadonlyArray<IFirstlineTicket>;
  readonly onClick: (item: IFirstlineTicket) => void;
}) {
  return (
    <UnorderedListWrapper>
      {props.items.map(item => (
        <DownlineEventTicketItem
          item={item}
          key={`${item.ticket?.ticketType ?? ''}_${item.customer.userId}`}
          onClick={props.onClick}
        />
      ))}
    </UnorderedListWrapper>
  );
}

function DownlineEventTicketItem(props: {
  readonly item: IFirstlineTicket;
  readonly onClick: (item: IFirstlineTicket) => void;
}) {
  return (
    <ListItemWrapper user={props.item.customer}>
      <div className="flex flex-row items-center gap-sm">
        {props.item.hasTicket && props.item.ticket ? (
          <div className="flex flex-col md:flex-row items-start md:items-center gap-xs">
            {props.item.ticket.ticketType && (
              <TicketType ticketType={props.item.ticket.ticketType} />
            )}
            {props.item.ticket.ticketType &&
              (props.item.ticket.purchaseDate ||
                props.item.ticket.isScanned) && (
                <div className="hidden md:block">
                  <Divider />
                </div>
              )}
            {props.item.ticket.purchaseDate && (
              <div className="hidden md:block">
                <TicketPurchaseDate date={props.item.ticket.purchaseDate} />
              </div>
            )}
            {props.item.ticket.isScanned &&
              (props.item.ticket.purchaseDate ||
                props.item.ticket.ticketType) && (
                <div className="hidden md:block">
                  <Divider />
                </div>
              )}
            {props.item.ticket.isScanned && (
              <div className="hidden md:block">
                <TicketScanned />
              </div>
            )}
          </div>
        ) : (
          <NoTicket />
        )}

        {props.item.customer.hasDownline ? (
          <IconButton
            onClick={() => props.onClick(props.item)}
            name={IconName.ChevronDown}
            size={16}
          />
        ) : (
          <div className="w-[48px]"></div>
        )}
      </div>
    </ListItemWrapper>
  );
}

function Divider() {
  return <div className="min-h-[32px] w-[1px] bg-silver"></div>;
}

function UnorderedListWrapper(props: { readonly children: ReactNode }) {
  return <ul className="flex flex-col gap-xs">{props.children}</ul>;
}

function ListItemWrapper(props: {
  readonly children: ReactNode;
  readonly user: IUserProfileSimplified | IUserProfile;
}) {
  return (
    <li className="flex flex-row items-center justify-between shadow-md rounded-md bg-white p-xs">
      <UserHeader
        user={props.user}
        showQualification
        showCheckAssurance
        showNewRegistration
        showBadges
      />
      {props.children}
    </li>
  );
}
