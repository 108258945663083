import { UserAvatar } from 'components/icons';
import { useGlobalConfigsContext } from 'contexts';
import {
  AccountChildNavigationGroup,
  AccountChildNavigationItem
} from 'features/account';
import { PII, usePII } from 'features/pii';
import { useWalletAccount } from 'features/wallet/hooks/useWalletAccount';
import { IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import AuthenticationService from 'services/AuthenticationService';

export function AccountRoute() {
  const { t, activeLanguage } = useTranslation();
  const {
    previewMode: { isImpersonator }
  } = useGlobalConfigsContext();
  const { pii } = usePII();
  const { data: walletAccount } = useWalletAccount(
    AuthenticationService.user.userId
  );

  return (
    <section className="m-auto">
      {!_.isUndefined(AuthenticationService.user) && (
        <div className="flex flex-row flex-nowrap gap-xs">
          <UserAvatar
            huge
            userType={
              AuthenticationService.user.isDiscountCustomer
                ? 'discount-customer'
                : AuthenticationService.user.isTeampartner
                ? 'teampartner'
                : 'customer'
            }
            userName={pii(AuthenticationService.user.name, 'name')}
          />
          <div>
            <h1>
              <PII value={AuthenticationService.user.name} type="name" />
            </h1>
            {!_.isUndefined(AuthenticationService.user.registrationDate) && (
              <p className="text-silver">
                {t('Registered on {{- longDateFormat}}', {
                  longDateFormat: pii(
                    AuthenticationService.user.registrationDate.toLocaleString(
                      activeLanguage,
                      'long'
                    ),
                    'fixed-date'
                  )
                })}
              </p>
            )}
            <h2 className="text-silver">
              #<PII value={AuthenticationService.user.userId} type="ids" />
            </h2>
          </div>
        </div>
      )}

      <AccountChildNavigationGroup title={t('Account')}>
        <AccountChildNavigationItem
          title={t('Personal information')}
          icon={IconName.Person}
          href="/account/profile"
          telemetryId="ProfileButtonClick"
        />
        <AccountChildNavigationItem
          title={t('My documents')}
          icon={IconName.Documents}
          href="/account/documents"
          telemetryId="DocumentButtonClick"
        />
        <AccountChildNavigationItem
          title={t('Change password')}
          description={t('Passwords are managed outside the PM Office.')}
          icon={IconName.Key}
          href={
            !isImpersonator
              ? `${process.env.REACT_APP_IDSRV_API}/account/password/change?theme=office`
              : ''
          }
          telemetryId="ChangePasswordButtonClick"
        />
        <AccountChildNavigationItem
          title={t('Payment methods')}
          description={t('Manage your payment instruments in PM Payments.')}
          icon={IconName.CreditCard}
          href={
            '' +
            process.env.REACT_APP_LINK_PAYMENTS_MANAGE +
            `?locale=${activeLanguage}`
          }
          telemetryId="ManagePaymentsButtonClick"
        />
        <AccountChildNavigationItem
          title={t('Payouts')}
          description={t(
            'Choose how to receive your comissions and bonus payments.'
          )}
          icon={IconName.MoneyTransfer}
          href="/account/payouts"
          telemetryId="PayoutsButtonClick"
        />
        <AccountChildNavigationItem
          title={t('My Orders')}
          description={t(
            'Access the comprehensive list containing all orders made through Event pages, the Webshop, or other supported offline methods.'
          )}
          icon={IconName.Receipt}
          href="/account/orders"
          telemetryId="OrdersButtonClick"
        />
        {walletAccount && (
          <AccountChildNavigationItem
            title={t('PM Credit')}
            icon={IconName.Coins}
            href="/account/pmcredit"
            telemetryId="PMWalletClick"
          />
        )}
      </AccountChildNavigationGroup>

      <AccountChildNavigationGroup title={t('Application settings')}>
        <AccountChildNavigationItem
          title={t('Language')}
          description={t('Select the language you prefer.')}
          icon={IconName.Language}
          href="/account/language"
          telemetryId="LanguageButtonClick"
        />
        <AccountChildNavigationItem
          title={t('General settings')}
          description={t('Privacy and Accessibility settings.')}
          icon={IconName.Gear}
          href="/account/app-settings"
          telemetryId="GeneralSettingsButtonClick"
        />
        <AccountChildNavigationItem
          title={t("What's New")}
          description={t('Discover the latest features.')}
          icon={IconName.Megaphone}
          href="/whatsnew"
          telemetryId="WhatsNewButtonClick"
        />
      </AccountChildNavigationGroup>

      <AccountChildNavigationGroup title={t('External Resources')}>
        <AccountChildNavigationItem
          title={t('Team Partner registration')}
          telemetryId="ExternalLinkClick_TPRegistration"
          icon={IconName.Add}
          href={'' + process.env.REACT_APP_LINK_REGISTRATION}
        />
        <AccountChildNavigationItem
          title={t('Partner area')}
          telemetryId="ExternalLinkClick_PartnerArea"
          icon={IconName.PM}
          href={'' + process.env.REACT_APP_LINK_PARTNER_AREA}
        />
        <AccountChildNavigationItem
          title={t('FitLine shop')}
          telemetryId="ExternalLinkClick_FitLineShop"
          icon={IconName.FitLine}
          href={'' + process.env.REACT_APP_LINK_WEBSHOP}
        />
      </AccountChildNavigationGroup>
    </section>
  );
}
