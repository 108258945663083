import { Disclaimer } from 'components/text';
import { EventsBackButton } from 'features/events/components/EventsBackButton';
import { EventCard } from 'features/events/EventCard';
import { usePromotedEvents } from 'features/events/hooks/usePromotedEvents';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useOutlet } from 'react-router-dom';

export function EventsRoute() {
  const { data: promotedEvents, error } = usePromotedEvents();
  const { t } = useTranslation();
  const outlet = useOutlet();

  if (outlet) {
    return (
      <section className="space-y-md">
        <EventsBackButton />
        {outlet}
      </section>
    );
  }

  return (
    <>
      <h1>{t('Upcoming Events')}</h1>
      {!_.isUndefined(error) && (
        <Disclaimer
          message={t(
            'Something went wrong. Could not fetch list of upcoming events. Please, try again later or contact the support team.'
          )}
          type="error"
        />
      )}

      {!_.isUndefined(promotedEvents) && promotedEvents.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 md:gap-sm mt-sm">
          {promotedEvents.map(event => {
            return <EventCard eventId={event.id} key={event.id} />;
          })}
        </div>
      )}
      {!_.isUndefined(promotedEvents) && promotedEvents.length === 0 && (
        <div className="flex-1 flex flex-col items-center mt-md mb-md">
          <Icon name={IconName.Calendar} size={48} className="text-silver" />
          <p className="text-silver mt-sm">
            {t('There are no upcoming events in your country.')}
          </p>
        </div>
      )}
    </>
  );
}
