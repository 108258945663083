import { ScriptableContext } from 'chart.js';

import { DevelopmentChartColors } from './constants';
import { IDevelopmentChartDataPoint } from './types';

export function computeDevelopmentChartDataSet(
  negativeDataPoints: IDevelopmentChartDataPoint[],
  positiveDataPoints: IDevelopmentChartDataPoint[]
) {
  const diffDataPoints = positiveDataPoints.map(positiveDataPoint => {
    const negativeDataPoint = negativeDataPoints.find(
      dp => dp.label === positiveDataPoint.label
    );

    return {
      label: positiveDataPoint.label,
      value:
        positiveDataPoint.value -
        (negativeDataPoint ? negativeDataPoint.value : 0)
    };
  });

  return [
    {
      data: negativeDataPoints.map(dp => -dp.value),
      fill: false,
      borderColor: DevelopmentChartColors.Red,
      backgroundColor: DevelopmentChartColors.Red,
      tension: 0.4
    },
    {
      data: diffDataPoints.map(dp => dp.value),
      fill: {
        target: 'origin',
        above: DevelopmentChartColors.Green,
        below: DevelopmentChartColors.Red
      },
      borderWidth: 0,
      pointRadius: 2,
      tension: 0.4,
      backgroundColor: ({ raw }: ScriptableContext<'line'>) => {
        return Number(raw) > 0
          ? DevelopmentChartColors.Green
          : DevelopmentChartColors.Red;
      }
    },
    {
      data: positiveDataPoints.map(dp => dp.value),
      fill: false,
      borderColor: DevelopmentChartColors.Green,
      backgroundColor: DevelopmentChartColors.Green,
      tension: 0.4
    }
  ];
}

export function computeDevelopmentChartLabels(
  dps: IDevelopmentChartDataPoint[]
) {
  return dps.map(dp => dp.label);
}
