import { ErrorHandler } from 'components/errors/ErrorHandler';
import { Pagination } from 'components/pagination';
import { LoadingSpinner } from 'components/spinners';
import { useBaskets } from 'features/baskets';
import { CreateNewBasketButton } from 'features/baskets/components/create-basket-button/CreateNewBasketButton';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useState } from 'react';

import { BasketsListItem } from './BasketsListItem';

const DEFAULT_COMMITMENT_LIST_PAGE_SIZE = 20;

export function BasketsList() {
  const { activeLanguage } = useTranslation();
  const [pageDetails, setPageDetails] = useState<{
    readonly page: number;
    readonly perPage: number;
  }>({
    page: 1,
    perPage: DEFAULT_COMMITMENT_LIST_PAGE_SIZE
  });

  const {
    data: basketsList,
    isLoading,
    error
  } = useBaskets(pageDetails.page, pageDetails.perPage, activeLanguage);

  if (error) {
    return <ErrorHandler err={error} />;
  }

  if (isLoading) {
    return (
      <div className="w-full flex justify-center p-md">
        <LoadingSpinner smallSize />
      </div>
    );
  }

  if (basketsList && basketsList.baskets.length === 0) {
    return <EmptyState />;
  }

  return (
    <div>
      <div className="flex justify-end mb-sm">
        <Pagination
          currentPage={pageDetails.page}
          onPageChange={p =>
            setPageDetails({
              page: p,
              perPage: DEFAULT_COMMITMENT_LIST_PAGE_SIZE
            })
          }
          totalPages={basketsList?.paginationInfo.totalPages ?? 0}
          siblingCount={2}
        />
      </div>
      {!_.isUndefined(basketsList) && basketsList.baskets.length > 0 && (
        <ul className="bg-white shadow-md rounded-md">
          {basketsList.baskets.map(basket => {
            return <BasketsListItem {...basket} key={basket.basket.basketId} />;
          })}
        </ul>
      )}
    </div>
  );
}

function EmptyState() {
  const { t } = useTranslation();

  return (
    <div className="py-xl px-md flex flex-col items-center justify-center gap-sm rounded-md bg-lightGray">
      <p className="text-center">{t("You haven't created any baskets yet.")}</p>
      <CreateNewBasketButton />
    </div>
  );
}
