import { ErrorHandler } from 'components/errors/ErrorHandler';
import { LoadingSpinner } from 'components/spinners';
import { IS_PWA } from 'contants';
import { PromocodeFAQButton } from 'libs/cms';
import { Fragment } from 'react/jsx-runtime';

import { CreatePromocode, PromocodeTicket } from './components';
import { usePromocode } from './hooks';

export function Promocode() {
  const {
    promocode,
    error,
    isLoading,
    createPromocodeSucceess,
    createPromocode
  } = usePromocode();

  if (error) {
    return <ErrorHandler err={error} />;
  }

  if (isLoading) {
    return (
      <div className="w-full flex justify-center p-md">
        <LoadingSpinner smallSize />
      </div>
    );
  }

  return (
    <Fragment>
      {promocode ? (
        <div className={createPromocodeSucceess ? 'animate-zoom-in' : ''}>
          <PromocodeTicket
            id={promocode.id}
            code={promocode.code}
            countryCode={promocode.countryCode}
            status={promocode.status}
            url={promocode.url}
          />
        </div>
      ) : (
        <CreatePromocode onCreate={createPromocode} />
      )}
      <div
        className={
          IS_PWA
            ? 'fixed right-[16px] bottom-[72px] pmi-safe-area-inset-b pmi-safe-area-inset-r'
            : 'fixed right-sm bottom-sm md:right-lg md:bottom-lg'
        }
      >
        <PromocodeFAQButton />
      </div>
    </Fragment>
  );
}
