import { useGlobalConfigsContext } from 'contexts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import { formatPercentLocalized } from 'utils';

interface IProgressBarProps {
  readonly percentage: number;
}

export function ProgressBar(props: IProgressBarProps) {
  const { activeLanguage } = useTranslation();
  const {
    app: {
      office: { enableAnimations }
    }
  } = useGlobalConfigsContext();
  const progressLineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Defer the execution to ensure the animation executes
    _.defer(() => {
      if (progressLineRef.current) {
        progressLineRef.current.style.width = `${props.percentage}%`;
      }
    });

    () => {
      if (progressLineRef.current) {
        progressLineRef.current.style.width = '0';
      }
    };
  });

  return (
    <div>
      <h2
        className={`${
          props.percentage === 100 ? 'text-success' : 'text-primary'
        } font-bold`}
      >
        <CountUp
          end={props.percentage}
          duration={1.5}
          formattingFn={n => formatPercentLocalized(activeLanguage, n)}
        />
      </h2>
      <div className="h-progress-bar w-full rounded-lg bg-secondary">
        <div
          ref={progressLineRef}
          style={{
            transition: '1s all ease-in-out',
            backgroundImage:
              props.percentage === 100
                ? 'linear-gradient(270deg, #0f9a65 0%, #58d083 100%)'
                : 'linear-gradient(270deg, #0066C0 0%, #71BDFF 100%)'
          }}
          className={`${
            enableAnimations ? '' : 'pmi-force-transition-none'
          } w-0 h-full rounded-lg`}
        ></div>
      </div>
    </div>
  );
}
