// eslint-disable-next-line no-restricted-imports
import {
  IoCheckmarkCircleOutline,
  IoPersonOutline,
  IoReceiptOutline,
  IoStorefrontOutline
} from 'react-icons/io5';

import { WizardStepId } from './NewBasketContext';

interface IWizardStepIconProps {
  readonly step: WizardStepId;
}

export function WizardStepIcon(props: IWizardStepIconProps) {
  switch (props.step) {
    case 'owner':
      return <IoPersonOutline size={24} />;
    case 'content':
      return <IoStorefrontOutline size={24} />;
    case 'summary':
      return <IoReceiptOutline size={24} />;
    case 'done':
      return <IoCheckmarkCircleOutline size={24} />;
  }
}
