import { PageContent } from 'components/layouts/page-content/PageContent';
import { PageHeader } from 'components/layouts/page-header/PageHeader';
import { WizardContentStep } from 'features/baskets/FullscreenWizardLayout2';
import { useEffect } from 'react';

import { BasketCreationSuccess } from './BasketCreationSuccess';
import { BasketOwnerSelection } from './BasketOwnerSelection';
import { BasketProductCatalog } from './BasketProductCatalog';
import { CancelBasketCreationButton } from './CancelBasketCreationButton';
import { useNewBasketContext } from './NewBasketContext';
import { useStepI18n } from './useStepI18n';
import { BasketButton } from './wizard-steps/content/BasketButton';
import { WizardHorizontalProgressTracker } from './WizardHorizontalProgressTracker';
import { WizardSection } from './WizardSection';

export function NewBasketWizard() {
  const { id: activeStep, steps, dispatchStepEvent } = useNewBasketContext();
  const tStep = useStepI18n();

  const showSuccessPage = activeStep === steps[3];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [activeStep]);

  return (
    <>
      <PageHeader sticky={false}>
        <div className="max-w-[1500px] w-full m-auto grid grid-cols-5 md:grid-cols-3">
          {!showSuccessPage ? (
            <CancelBasketCreationButton />
          ) : (
            <div className=""></div>
          )}
          <div className="flex col-span-3 md:col-span-1">
            <WizardHorizontalProgressTracker
              activeStep={activeStep}
              availableSteps={steps.slice(0, steps.length - 1)}
            />
          </div>
          <div className=""></div>
        </div>
      </PageHeader>
      <PageContent>
        <section className="m-auto max-w-4xl space-y-sm">
          {showSuccessPage ? (
            <div className="bg-white rounded-md shadow-md p-lg">
              <BasketCreationSuccess />
            </div>
          ) : (
            <>
              <WizardSection
                step={steps[0]}
                index={1}
                completed={[steps[1], steps[2], steps[3]].includes(activeStep)}
                title={tStep(steps[0])}
                active={activeStep === steps[0]}
                onEdit={() => {
                  dispatchStepEvent({ type: 'edit-step', value: 'owner' });
                }}
              >
                <BasketOwnerSelection />
              </WizardSection>
              <WizardSection
                step={steps[1]}
                index={2}
                completed={[steps[2], steps[3]].includes(activeStep)}
                title={tStep(steps[1])}
                active={activeStep === steps[1]}
                onEdit={() => {
                  dispatchStepEvent({ type: 'edit-step', value: 'content' });
                }}
                editOnDetails
                headerActiveContent={<BasketButton />}
              >
                <BasketProductCatalog />
              </WizardSection>
              <WizardSection
                step={steps[2]}
                index={3}
                completed={[steps[3]].includes(activeStep)}
                title={tStep(steps[2])}
                active={activeStep === steps[2]}
              >
                <WizardContentStep />
              </WizardSection>
            </>
          )}
        </section>
      </PageContent>
    </>
  );
}
