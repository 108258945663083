import { useQuery } from '@tanstack/react-query';
import { fetchUser } from 'services';

const USERS_QUERY_KEY = 'pmi.web.office__um_user';
const TTL = 900000;

export function useUser(userInternalOrPublicId: string) {
  const query = useQuery({
    queryKey: [USERS_QUERY_KEY, userInternalOrPublicId],
    queryFn: () => fetchUser(userInternalOrPublicId),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
