import { SupportedLanguage } from '@pmi.web/localization';
import { useQuery } from '@tanstack/react-query';

import { fetchVirtualWarehouseFAQsStory } from './FAQService';

const FAQS_SLUG = 'virtual-warehouse-faq';

const VW_FAQS_QUERY_KEY = 'pmi.web.office__vw_faqs';
const TTL = 900000; // 15 minutes in milliseconds

export function useVirtualWarehouseFAQs(language: SupportedLanguage) {
  const query = useQuery({
    queryKey: [VW_FAQS_QUERY_KEY, FAQS_SLUG, language],
    queryFn: () => fetchVirtualWarehouseFAQsStory(FAQS_SLUG, language),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
