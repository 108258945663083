import { BottomSheetContainer } from 'components/modals';
import { useTranslation } from 'libs/translations';
import { useReducer } from 'react';

import { PromocodeFAQStoryblokContent } from './PromocodeFAQStoryblokContent';

const defaultState: FAQButtonState = {
  isFAQOpen: false,
  isPromoVideoOpen: false
};

export function PromocodeFAQButton() {
  const { t } = useTranslation();
  const [state, dispatchStateEvent] = useReducer(reducer, defaultState);

  return (
    <>
      <button
        className="text-center rounded-full text-white p-sm bg-primary w-[52px] h-[52px] text-[22px] shadow-md"
        onClick={() => dispatchStateEvent({ type: 'open-faq' })}
      >
        ?
      </button>
      <BottomSheetContainer
        open={state.isFAQOpen}
        onDismiss={() => dispatchStateEvent({ type: 'close-faq' })}
      >
        <h1>{t("FAQ's")}</h1>
        <PromocodeFAQStoryblokContent />
      </BottomSheetContainer>
    </>
  );
}

interface FAQButtonState {
  readonly isFAQOpen: boolean;
  readonly isPromoVideoOpen: boolean;
}

type FAQButtonStateAction = { type: 'open-faq' } | { type: 'close-faq' };

function reducer(
  state: FAQButtonState,
  action: FAQButtonStateAction
): FAQButtonState {
  if (action.type === 'open-faq') {
    return {
      isFAQOpen: true,
      isPromoVideoOpen: false
    };
  }

  if (action.type === 'close-faq') {
    return {
      isFAQOpen: false,
      isPromoVideoOpen: false
    };
  }

  return state;
}
