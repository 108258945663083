import { Timestamp } from 'libs/time';
import { BasketStatus, IBasket, IBasketList } from 'types';

import { IBasketApi } from './types/IBasketApi';
import { IBasketsPaginatedApi } from './types/IBasketsPaginatedApi';

export function parseIBasketPaginatedAPIResponse(
  apiResponse: IBasketsPaginatedApi
): IBasketList {
  return {
    ...apiResponse,
    baskets: apiResponse.results.map(parseAPIBasket)
  };
}

export function parseAPIBasket(apiBasket: IBasketApi): IBasket {
  return {
    basket: {
      ...apiBasket.basket,
      status: apiBasket.basket.status.toLowerCase() as BasketStatus,
      createdOn: Timestamp.createOrThrow(apiBasket.basket.createdOn),
      autoDeleteOn: Timestamp.createOrThrow(apiBasket.basket.autoDeleteOn)
    },
    checkoutUrl: apiBasket.checkoutUrl
  };
}
