import {
  Chart as ChartJS,
  registerables as ChartJSRegisterables,
  ScriptableContext
} from 'chart.js';
import type { ChartArea } from 'chart.js';
import { usePII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import { useCallback, useEffect, useRef, useState } from 'react';
import { formatNumberLocalized } from 'utils';

ChartJS.register(...ChartJSRegisterables);

const NeutralTrendColors = {
  color: 'rgb(0, 75, 141)',
  fullOpacityColor: 'rgba(191, 210, 226, 1)',
  zeroOpacityColor: 'rgba(191, 210, 226, 0)'
};

interface IAreaChartProps {
  readonly xAxesLabel?: string;
  readonly yAxesLabel?: string;
  readonly isIllustration?: boolean;
  readonly dataPoints: IChartDataPoint[];
}

interface IChartDataPoint {
  readonly label: string;
  readonly value: number;
}

export function AreaChart(props: IAreaChartProps) {
  const { activeLanguage } = useTranslation();
  const { isPrivacyGuardEnabled } = usePII();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chartRef, setChartRef] = useState<ChartJS | null>(null);

  const renderChart = useCallback(() => {
    if (!canvasRef.current) {
      return;
    }

    const chart = new ChartJS(canvasRef.current, {
      type: 'line',
      data: {
        labels: computeChartLabels(props.dataPoints),
        datasets: computeChartDataSet(props.dataPoints)
      },
      options: {
        scales: {
          x: {
            display: props.isIllustration ? false : true,
            title: {
              display: true,
              text: props.xAxesLabel
            }
          },
          y: {
            beginAtZero: true,
            grace: '5%',
            type: 'linear',
            display:
              props.isIllustration || isPrivacyGuardEnabled ? false : true,
            title: {
              display: true,
              text: props.yAxesLabel
            },
            ticks: {
              callback: value => {
                return formatNumberLocalized(activeLanguage, value as number);
              }
            }
          }
        },
        elements: {
          point: {
            radius: isPrivacyGuardEnabled || props.isIllustration ? 0 : 3,
            hoverRadius: isPrivacyGuardEnabled || props.isIllustration ? 0 : 3
          }
        },
        plugins: {
          tooltip: {
            enabled:
              isPrivacyGuardEnabled || props.isIllustration ? false : true
          },
          legend: {
            display: false
          }
        }
      }
    });

    if (props.isIllustration) {
      chart.options.events = [];
      chart.options.maintainAspectRatio = false;
    }

    setChartRef(chart);
  }, [
    activeLanguage,
    isPrivacyGuardEnabled,
    props.dataPoints,
    props.isIllustration,
    props.xAxesLabel,
    props.yAxesLabel
  ]);

  const destroyChart = () => {
    if (chartRef) {
      chartRef.destroy();
      setChartRef(null);
    }
  };

  useEffect(() => {
    renderChart();

    return () => destroyChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chartRef) {
      return;
    }

    chartRef.data.labels = computeChartLabels(props.dataPoints);
    chartRef.data.datasets = computeChartDataSet(props.dataPoints);
    chartRef.update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataPoints]);

  return <canvas ref={canvasRef} role="img"></canvas>;
}

function computeChartLabels(dps: IChartDataPoint[]) {
  return dps.map(dp => dp.label);
}

function computeBgGradient(
  ctx: CanvasRenderingContext2D,
  chartArea: ChartArea
) {
  const { top, bottom } = chartArea;
  const gradientColor = ctx.createLinearGradient(0, top, 0, bottom);

  gradientColor.addColorStop(0, NeutralTrendColors.fullOpacityColor);
  gradientColor.addColorStop(1, NeutralTrendColors.zeroOpacityColor);
  return gradientColor;
}

function computeChartDataSet(dps: IChartDataPoint[]) {
  return [
    {
      data: dps.map(dp => dp.value),
      fill: true,
      borderColor: NeutralTrendColors.color,
      backgroundColor: (context: ScriptableContext<'line'>) => {
        const { ctx, chartArea } = context.chart;
        if (!chartArea) {
          return 'rgba(0, 0, 0, 0)';
        }

        return computeBgGradient(ctx, chartArea);
      },
      tension: 0.1
    }
  ];
}
