import { IIconProps } from '../types/IIconProps';

export function NetworkFillIcon(props: IIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      className={props.className}
      viewBox="0 0 24 24"
    >
      <g fill="currentColor">
        <path
          d="M20,15a3.96,3.96,0,0,0-2.484.887L13,12.5V7.859a4,4,0,1,0-2,0V12.5L6.484,15.887A3.985,3.985,0,1,0,8,19a3.971,3.971,0,0,0-.3-1.522L12,14.25l4.3,3.228A4,4,0,1,0,20,15Z"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
}
