import { Timestamp } from 'libs/time';
import { IPromoShop, IShopBanner, IShopPromotions } from 'types';

import { IApiPromoShop } from './types/IApiPromoShop';
import { IApiShopBanner } from './types/IApiShopBanner';
import { IApiShopPromotions } from './types/IApiShopPromotions';

export function parseApiPromoShop(apiPromoShop: IApiPromoShop): IPromoShop {
  return {
    id: apiPromoShop.id,
    shopUrl: `${apiPromoShop.shopUrl}?autosignin`,
    promotions: apiPromoShop.promotions
      ? parseApiPromotions(apiPromoShop.promotions)
      : undefined,
    isVirtualWarehouseEnabled: apiPromoShop.isVirtualWarehouseEnabled,
    isPromoCodeEnabled: apiPromoShop.isPromoCodeEnabled,
    isAffiliateDiscountEnabled: apiPromoShop.isAffiliateDiscountEnabled,
    canCreateCommitments: apiPromoShop.canCreateCommitments
  };
}

function parseApiPromotions(
  apiPromotions: IApiShopPromotions
): IShopPromotions {
  return {
    banners: (apiPromotions.banners ?? [])?.map(parseShopBanner)
  };
}

function parseShopBanner(shopBanner: IApiShopBanner): IShopBanner {
  return {
    ...shopBanner,
    countdown: shopBanner.countdown
      ? Timestamp.create(shopBanner.countdown)
      : undefined
  };
}
