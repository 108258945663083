import { FormattedUnitValue } from 'components/text';
import { useTranslation } from 'libs/translations';

interface IInvoiceArticle {
  readonly name?: string;
  readonly imgSrc?: string;
  readonly articleNumber: string;
  readonly quantity: number;
  readonly currencyCode: string;
  readonly itemGross: number;
  readonly points: number;
}

interface IInvoiceProductListProps {
  readonly items: ReadonlyArray<IInvoiceArticle>;
}

export function InvoiceProductList({ items }: IInvoiceProductListProps) {
  const { t } = useTranslation();

  return (
    <ul className="space-y-sm">
      {items.map(item => {
        return (
          <li
            key={item.articleNumber}
            className="flex flex-row items-center gap-xs"
          >
            {item.imgSrc && (
              <div className="w-[56px] h-[56px] rounded-md flex-shrink-0 bg-cover bg-no-repeat bg-center">
                <img
                  title={item.name ?? item.articleNumber}
                  src={item.imgSrc}
                  className="rounded-md"
                />
              </div>
            )}
            <div className="flex-grow">
              <p className="line-clamp-1">{item.name ?? item.articleNumber}</p>
              <p className="pmi-caption leading-[24px]">
                {t('Quantity: {{number}}', {
                  number: item.quantity
                })}
              </p>
            </div>
            <div className="grid grid-rows-2 text-right">
              <FormattedUnitValue
                unit={item.currencyCode}
                value={item.itemGross * item.quantity}
              />
              {item.points > 0 && (
                <FormattedUnitValue
                  unit="points"
                  value={item.points * item.quantity}
                />
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
}
