import { RadioGroup } from '@headlessui/react';
import { SupportedLanguage } from '@pmi.web/localization';
import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import {
  LANGUAGE_COUNTRY_CODES,
  LANGUAGE_COUNTRY_NAMES_LOCALIZED,
  LANGUAGE_NAMES_LOCALIZED
} from 'contants';
import { useGlobalConfigsContext } from 'contexts';
import { AccountBackButton } from 'features/account';
import { usePatchMeServerState } from 'hooks';
import { CountryFlag, Icons } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import { triggerSuccessToast, triggerWarningToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import { Fragment, useEffect, useState } from 'react';

export function AccountLanguageRoute() {
  const { t, activeLanguage } = useTranslation();
  const {
    device: { supportsHover },
    app: {
      me: { preferedLanguage }
    }
  } = useGlobalConfigsContext();
  const { patchPreferences, isError, error, isSuccess, isPending } =
    usePatchMeServerState();

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isError) {
      triggerWarningToast(
        t(
          'Something went wrong. It seems we cannot update your preferred language right now. Please try again later or contact the support team.'
        )
      );
      console.error(`Failed to new save language preference`, error);
    }
  }, [isError, error, t]);

  useEffect(() => {
    if (isSuccess) {
      trackEvent({
        name: 'UILanguageUpdate',
        properties: {
          lang: activeLanguage
        }
      });
      trackEvent({
        name: 'PreferedLanguageUpdate',
        properties: {
          from: preferedLanguage,
          to: activeLanguage
        }
      });

      triggerSuccessToast(
        t(
          'Your user interface language has been updated. Please remember your country settings remain the same.'
        )
      );
    }
  }, [activeLanguage, isSuccess, preferedLanguage, t]);

  useEffect(() => {
    if (isPending) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isPending]);

  const changeLanguage = async (to: SupportedLanguage) => {
    patchPreferences({ preferedLanguage: to });
  };

  return (
    <>
      <PageTitle />
      {isLoading && (
        <div className="fixed inset-0 h-screen w-screen pmi-glass-white z-10 flex items-center justify-center">
          <LoadingSpinner smallSize />
        </div>
      )}
      <section className="m-auto">
        <AccountBackButton />

        <h2 className="text-primary mt-md mb-sm">
          {t('Choose your prefered language')}
        </h2>

        <Disclaimer
          message={t(
            "On this page, you can change the language of the user interface, but please note that this doesn't affect the PM Office functionalities, which are determined by your registered country. Content, analyses, and more are relevant only if applicable in your registered country."
          )}
        />

        <div className="bg-white shadow-md rounded-md">
          <RadioGroup
            value={preferedLanguage}
            onChange={changeLanguage}
            className="p-xxs grid grid-cols-1 lg:grid-cols-2 gap-0 mt-sm"
          >
            {Object.values(SupportedLanguage).map(supportedLanguage => (
              /* Use the `active` state to conditionally style the active option. */
              /* Use the `checked` state to conditionally style the checked option. */
              <RadioGroup.Option
                key={supportedLanguage}
                value={supportedLanguage}
                as={Fragment}
              >
                {({ checked }) => (
                  <div
                    className={`flex flex-row items-center gap-sm transition-all list-none cursor-pointer px-4 py-3 ${
                      supportsHover
                        ? 'hover:bg-secondary hover:text-active hover:rounded-md'
                        : ''
                    } ${checked ? 'bg-secondary rounded-md' : ''}`}
                  >
                    {checked && (
                      <Icons.Common.Checkmark
                        className="text-white bg-active rounded-full p-xxs"
                        size={24}
                      />
                    )}
                    <CountryFlag
                      countryCode={LANGUAGE_COUNTRY_CODES[supportedLanguage]}
                    />
                    <p className="items-baseline text-ellipsis overflow-clip whitespace-nowrap text-silver">
                      <span className={checked ? 'text-active' : 'text-gray'}>
                        {LANGUAGE_NAMES_LOCALIZED[supportedLanguage]}
                      </span>
                      <span className="ml-xs text-[12px] uppercase text-silver">
                        {LANGUAGE_COUNTRY_NAMES_LOCALIZED[supportedLanguage]}
                      </span>
                    </p>
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
      </section>
    </>
  );
}
