import { useTranslation } from 'libs/translations';

import { WizardStepId } from './NewBasketContext';

export function useStepI18n() {
  const { t } = useTranslation();

  const customT = (step: WizardStepId) => {
    switch (step) {
      case 'owner':
        return t('Customer');
      case 'content':
        return t('Products');
      case 'summary':
        return t('Summary');
      case 'done':
        return t('Done');
    }
  };

  return customT;
}
