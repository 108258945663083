import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { Trans } from 'react-i18next';

export function RequirePayquickerRegistration() {
  const { t } = useTranslation();

  return (
    <div className="space-y-sm">
      <h1>{t('Your PM Pay+ registration still needs to be completed!')}</h1>
      <Trans t={t} i18nKey="RequirePayquickerRegistration">
        <p>
          You successfully upgraded to PM Pay+. However, your registration still
          needs to be completed. Along with your next payout, you will receive
          an email with further instructions on how to register. Don't forget to
          complete your registration so you can access your future payouts.
        </p>
      </Trans>
    </div>
  );
}
