import { localizedCountryName } from '@pmi.web/countries';
import { QuickStarterBadge } from 'components/badges';
import { RankBadge } from 'components/badges/qualification/RankBadge';
import { LabelledText } from 'components/text';
import { QUALIFICATIONS } from 'contants';
import { PII, usePII } from 'features/pii';
import { CountryFlag, Icon, IconName } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { PiSealCheckFill } from 'react-icons/pi';
import { IUserProfile } from 'types';

import './ProfileDetails.scss';

export function ProfileDetails(props: IUserProfile) {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  const badgesToRender = useMemo(() => {
    const badges = [];

    if (props.isQuickStarter) {
      badges.push({ key: 'quickstarter', elem: <QuickStarterBadge /> });
    }

    return badges;
  }, [props]);

  const callHandler = () => {
    trackEvent({
      name: 'ProfileCallButtonClick'
    });
    return true;
  };

  const sendEmailHandler = () => {
    trackEvent({
      name: 'ProfileEmailButtonClick'
    });
    return true;
  };

  return (
    <>
      <ul className="list-none text-center">
        {badgesToRender.map(badge => (
          <li className="inline-block mr-xs mb-xs" key={badge.key}>
            {badge.elem}
          </li>
        ))}
      </ul>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-sm">
        {props?.phoneNumber && (
          <a
            href={`tel:${pii(
              props.phoneNumber.replace(/[() -]/g, ''),
              'phone'
            )}`}
            onClick={callHandler}
            className="profile-details--call-button"
          >
            <Icon name={IconName.Call} size={16} />
            <PII value={props.phoneNumber} type="phone" />
          </a>
        )}
        {props?.email && (
          <a
            href={`mailto:${pii(props.email, 'email')}`}
            onClick={sendEmailHandler}
            className="profile-details--email-button"
          >
            <Icon name={IconName.Email} size={16} />
            <PII value={props.email} type="phone" />
          </a>
        )}
        {!_.isUndefined(props.rank) && !props.isDiscountCustomer && (
          <LabelledText
            label={t('Rank')}
            value={
              <span className="w-fit flex items-center">
                <RankBadge rank={props.rank} />
                <span className="ml-xxs">
                  {QUALIFICATIONS[props.rank].name}
                </span>
              </span>
            }
          />
        )}
        {props.isFitlineClubMember && (
          <LabelledText
            label={t('FitLine Club member')}
            value={
              <span className="w-fit flex items-center">
                <PiSealCheckFill size={20} className="text-success" />
              </span>
            }
          />
        )}
        {!_.isUndefined(props.registrationDate) && (
          <LabelledText
            label={t('Registration Date')}
            value={
              <PII
                value={props.registrationDate.toLocaleString(
                  activeLanguage,
                  'long'
                )}
                type="fixed-date"
              />
            }
          />
        )}
        {!_.isUndefined(props.birthDate) && (
          <LabelledText
            label={t('Birth Date')}
            value={
              <PII
                value={props.birthDate.toLocaleString(activeLanguage, 'long')}
                type="fixed-date"
              />
            }
          />
        )}
        <LabelledText
          label={t('Sponsor')}
          value={<PII value={props.sponsor.name} type="name" />}
        />
        {!_.isUndefined(props.coach) && (
          <LabelledText
            label={t('Coach')}
            value={<PII value={props.coach.name} type="name" />}
          />
        )}
        <LabelledText
          label={t('Country')}
          value={
            <CountryFlag
              countryCode={pii(props.countryCode, 'country')}
              renderLocalizedCountryName
            />
          }
        />
        {!_.isUndefined(props.address) && (
          <LabelledText
            label={t('Address')}
            value={
              <>
                {!_.isUndefined(props.address.street) && (
                  <p>
                    <PII value={props.address.street} type="address" />
                  </p>
                )}
                {!_.isUndefined(props.address.zipcode) && (
                  <p>
                    <PII value={props.address.zipcode} type="address" />
                  </p>
                )}
                {!_.isUndefined(props.address.city) && (
                  <p>
                    <PII value={props.address.city} type="address" />
                  </p>
                )}
                {!_.isUndefined(props.address.countryCode) && (
                  <p>
                    {localizedCountryName(
                      pii(props.address.countryCode, 'country'),
                      activeLanguage
                    )}
                  </p>
                )}
              </>
            }
          />
        )}
      </div>
    </>
  );
}
