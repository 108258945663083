import { BasketsList } from 'features/virtual-warehouse';
import { Outlet } from 'react-router-dom';

export function BasketsRoute() {
  return (
    <>
      <BasketsList />
      <Outlet />
    </>
  );
}
