import PMIWeb from '@pmi.web/ui';
import { PII } from 'features/pii';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICommitmentItem } from 'types';

export const DEFAULT_PRODUCT_IMAGE = '';

export function InventoryItem(props: ICommitmentItem) {
  return <InventoryItemV2 {...props} />;
}

function InventoryItemV2(props: ICommitmentItem) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const name = props.details.attributes?.name
    ? props.details.attributes.name.value
    : props.articleNumber;

  const imageSrc = props.details.media.find(
    m => m.type === 'image' && !_.isUndefined(m.url)
  );

  return (
    <article className="bg-white rounded-md p-xs md:p-sm h-full shadow relative flex flex-col gap-xs items-center">
      <img
        alt={props.articleNumber}
        src={imageSrc?.url ?? DEFAULT_PRODUCT_IMAGE}
        className="h-32 w-32 m-auto rounded-md"
      />
      <div className="absolute top-xs right-xs bg-lightGray text-silver rounded-full px-xs py-xxs font-bold pmi-caption">
        x<PII value={props.quantity + ''} type="number" />
      </div>
      <p className="line-clamp-3 h-[72px] text-center w-full">
        <span>{name}</span>
      </p>
      <PMIWeb.Components.PrimaryButton
        onClick={() => {
          trackEvent({
            name: 'VW_SellNow'
          });
          navigate(`/sales/createbasket?articles=${props.articleNumber}_1`, {
            state: { from: location }
          });
        }}
      >
        {t('Sell now')}
      </PMIWeb.Components.PrimaryButton>
    </article>
  );
}
