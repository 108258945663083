import { useGlobalConfigsContext } from 'contexts';
import { useNavigate } from 'react-router-dom';
import { BasketStatus, IBasket } from 'types';

import { CanceledBasketCard } from './CanceledBasketCard';
import { OpenBasketCard } from './OpenBasketCard';
import { PaymentAuthorizedBasketCard } from './PaymentAuthorizedBasketCard';
import { PaymentPendingBasketCard } from './PaymentPendingBasketCard';

export function BasketsListItem(basket: IBasket) {
  const navigate = useNavigate();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();

  const basketStatus = basket.basket.status;

  return (
    <li className="border border-transparent border-b-lightGray last:border-b-transparent">
      <button
        onClick={() => void navigate(basket.basket.basketId)}
        className={`text-left w-full p-sm grid grid-cols-[36px_1fr_20px] gap-sm items-center border border-transparent ${
          supportsHover
            ? 'hover:bg-secondary hover:text-primary hover:rounded-md hover:border-active transition-all'
            : ''
        }`}
      >
        {basketStatus === BasketStatus.Open && <OpenBasketCard {...basket} />}
        {basketStatus === BasketStatus.PaymentAuthorized && (
          <PaymentAuthorizedBasketCard {...basket} />
        )}
        {basketStatus === BasketStatus.PaymentPending && (
          <PaymentPendingBasketCard {...basket} />
        )}
        {basketStatus === BasketStatus.Canceled && (
          <CanceledBasketCard {...basket} />
        )}
      </button>
    </li>
  );
}
