import { UserHeader } from 'components/headers';
import { useScreenEvents } from 'contexts';
import { usePII } from 'features/pii';
import { Icon, IconName } from 'libs/icons';
import _ from 'lodash';
import { MouseEvent } from 'react';
import { IUserProfile } from 'types';

import './DownlineItem.scss';

interface IDownlineItemProps extends IUserProfile {
  readonly highlighted?: boolean;
  readonly onUserClick: () => void;
  readonly onUserExpansion: () => void;
}

export function DownlineItem(props: IDownlineItemProps) {
  const { xl } = useScreenEvents();
  const { pii } = usePII();

  const onUserClickHandler = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    props.onUserClick();
  };

  const onUserExpansionHandler = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    props.onUserExpansion();
  };

  return (
    <div
      className={
        props.highlighted
          ? 'downline-item downline-item--selected'
          : 'downline-item'
      }
    >
      <button
        className="downline-item--user-button"
        onClick={onUserClickHandler}
        title={`${pii(props.name, 'name')}, #${pii(props.userId, 'tpid')}`}
      >
        <UserHeader
          user={props}
          invertAvatarColors={props.highlighted}
          showBadges
          showQualification
          showCheckAssurance
          showNewRegistration
          disableNavigation
        />
        {xl && props.hasDownline && (
          <Icon
            name={IconName.ChevronDown}
            size={16}
            className="text-silver mr-sm"
          />
        )}
      </button>

      {!xl && props.hasDownline && _.isUndefined(props.countryUserId) && (
        <button
          title="Dashboard"
          onClick={onUserExpansionHandler}
          className="downline-item--expand-button"
        >
          <Icon name={IconName.ChevronDown} size={16} />
        </button>
      )}
    </div>
  );
}
