import _ from 'lodash';
import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer
} from 'react';
import { useSearchParams } from 'react-router-dom';

export type WizardStepId = 'owner' | 'content' | 'summary' | 'done';

interface NewBasketState {
  readonly id: WizardStepId;
  readonly userId: string | undefined;
  readonly prospectId: string | undefined;
  readonly checkoutUrl: string | undefined;
  readonly articles: Map<string, number>;
}

const defaultStep: NewBasketState = {
  id: 'owner',
  checkoutUrl: undefined,
  prospectId: undefined,
  userId: undefined,
  articles: new Map()
};

type NewBasketStateAction =
  | { type: 'edit-step'; value: WizardStepId }
  | { type: 'set-userid'; value: string }
  | { type: 'confirm-userid' }
  | { type: 'set-prospectid'; value: string }
  | { type: 'increment-article-quantity'; value: [string, number] }
  | { type: 'set-article-quantity'; value: [string, number] }
  | { type: 'confirm-articles' }
  | { type: 'set-basket-id'; value: string };

function reducer(
  state: NewBasketState,
  action: NewBasketStateAction
): NewBasketState {
  if (action.type === 'edit-step') {
    return {
      ...state,
      id: action.value
    };
  }

  if (action.type === 'set-userid') {
    return {
      ...state,
      userId: action.value,
      prospectId: undefined
    };
  }

  if (action.type === 'set-prospectid') {
    return {
      ...state,
      id: 'content',
      prospectId: action.value,
      userId: undefined
    };
  }

  if (action.type === 'increment-article-quantity') {
    const articles = new Map(state.articles);
    const [articleNumber, quantityUpdate] = action.value;
    const stateQuantity = state.articles.get(articleNumber) ?? 0;
    const newQuantity = quantityUpdate + stateQuantity;

    if (newQuantity === 0) {
      articles.delete(articleNumber);
    } else {
      articles.set(articleNumber, newQuantity);
    }

    return {
      ...state,
      articles
    };
  }

  if (action.type === 'set-article-quantity') {
    const articles = new Map(state.articles);
    const [articleNumber, quantity] = action.value;

    if (quantity === 0) {
      articles.delete(articleNumber);
    } else {
      articles.set(articleNumber, quantity);
    }

    return {
      ...state,
      articles
    };
  }

  if (action.type === 'confirm-articles') {
    return {
      ...state,
      id: 'summary'
    };
  }

  if (action.type === 'confirm-userid') {
    // If there are items, skip the content step. Let the user to decide to edit
    if (state.articles.size > 0) {
      return {
        ...state,
        id: 'summary'
      };
    }

    return {
      ...state,
      id: 'content'
    };
  }

  if (action.type === 'set-basket-id') {
    return {
      ...state,
      userId: undefined,
      prospectId: undefined,
      articles: new Map(),
      checkoutUrl: action.value,
      id: 'done'
    };
  }

  return state;
}

interface INewBasketContextValues extends NewBasketState {
  readonly dispatchStepEvent: Dispatch<NewBasketStateAction>;
  readonly steps: ReadonlyArray<WizardStepId>;
}

const StepSequence: ReadonlyArray<WizardStepId> = [
  'owner',
  'content',
  'summary',
  'done'
];

const NewBasketContext = createContext<INewBasketContextValues>({
  ...defaultStep,
  steps: StepSequence,
  dispatchStepEvent: _.noop
});

interface INewBasketContextProviderProps {
  readonly children: ReactNode;
}

export function NewBasketContextProvider(
  props: INewBasketContextProviderProps
) {
  const [searchParams] = useSearchParams();
  const [step, dispatchStepEvent] = useReducer(reducer, defaultStep);

  useEffect(() => {
    const urlProspectId = searchParams.get('prospectId') ?? undefined;
    if (urlProspectId) {
      dispatchStepEvent({
        type: 'set-prospectid',
        value: urlProspectId
      });
    }

    const urlUserId = searchParams.get('userId') ?? undefined;
    if (urlUserId) {
      dispatchStepEvent({
        type: 'set-userid',
        value: urlUserId
      });
      dispatchStepEvent({
        type: 'confirm-userid'
      });
    }

    const urlArticles: [string, number][] =
      searchParams
        .get('articles')
        ?.split(',')
        .map(s => {
          const [key, value] = s.split('_');
          return [key, Number(value)];
        }) ?? [];

    if (urlArticles.length > 0) {
      urlArticles.forEach(article => {
        dispatchStepEvent({
          type: 'set-article-quantity',
          value: article
        });
      });

      if (urlUserId || urlProspectId) {
        dispatchStepEvent({ type: 'confirm-articles' });
      }
    }
  }, []);

  useEffect(() => {
    if (step.id === 'summary' && step.articles.size === 0) {
      dispatchStepEvent({ type: 'edit-step', value: 'content' });
    }
  }, [step.articles.size, step.id]);

  return (
    <NewBasketContext.Provider
      value={{
        ...step,
        steps: StepSequence,
        dispatchStepEvent
      }}
    >
      {props.children}
    </NewBasketContext.Provider>
  );
}

export function useNewBasketContext() {
  const context = useContext(NewBasketContext);

  if (context === undefined) {
    throw new Error(
      'useNewBasketContext must be used within NewBasketContextProvider'
    );
  }

  return context;
}
