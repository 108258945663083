import { usePII } from 'features/pii';
import { useUser } from 'hooks';
import { useTranslation } from 'libs/translations';
import { IBasket } from 'types';

import { BasketCard } from './BasketCard';
import { BasketOwner } from '../basket/BasketOwner';

export function PaymentAuthorizedBasketCard(basket: IBasket) {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  const { data: userData } = useUser(basket.basket.customerId);
  const wasProspect =
    (userData?.createdAt.getTime() ?? 0) > basket.basket.createdOn.getTime();

  return (
    <BasketCard status={basket.basket.status}>
      <div className="flex flex-row items-center gap-sm flex-wrap">
        <BasketOwner
          basketOwnerId={basket.basket.customerId}
          basketCreadtedOn={basket.basket.createdOn}
        />
        {wasProspect && (
          <p className="pmi-caption text-silver font-bold bg-lightGray px-xs py-xxs rounded-full">
            {t('New customer since {{registrationDate}}', {
              registrationDate: pii(
                userData?.createdAt.toLocaleDateString(activeLanguage),
                'fixed-date'
              )
            })}
          </p>
        )}
      </div>
      <p className="pmi-caption mt-xs text-silver font-bold">
        {wasProspect
          ? t(
              "Well done! You've successfully sold one of the curated baskets and gained a new customer!"
            )
          : t('Congratulations on the successful sale of your curated basket!')}
      </p>
    </BasketCard>
  );
}
