import { Disclosure } from '@headlessui/react';
import { QualificationBadge } from 'components/badges';
import { ErrorHandler } from 'components/errors/ErrorHandler';
import { UserHeader } from 'components/headers';
import { Pagination } from 'components/pagination';
import { LoadingSpinner } from 'components/spinners';
import { FormattedUnitValue, TrendValue } from 'components/text';
import { AutoshipsColumn } from 'features/dashboard/widgets/firstline/columns/AutoshipsColumns';
import { usePII } from 'features/pii';
import {
  CountryFlag,
  FeatureIcon,
  FeatureIconName,
  Icon,
  IconName,
  Icons
} from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { BiWorld } from 'react-icons/bi';
// eslint-disable-next-line no-restricted-imports
import { PiSealCheckFill } from 'react-icons/pi';
import { IUserSearchParamsV2, TUserSearchSorting } from 'services';
import { IUserProfile, IUserSearchResults } from 'types';
import { computePercentageTrend } from 'utils';

import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import { useUsersSearch } from '../hooks/useUsersSearch';

const ITEMS_PER_PAGE = 20;

function booleanOrUndefined(value: string): boolean | undefined {
  if (value.toLowerCase() === 'true') {
    return true;
  }

  if (value.toLowerCase() === 'false') {
    return false;
  }

  return undefined;
}

export function AnalyticsResults() {
  const { t } = useTranslation();
  const { filterParams, searchParams, sortParams } = useAnalyticsContext();

  const [pageNumber, setPageNumber] = useState<number>(1);

  const searchQuery: IUserSearchParamsV2 = useMemo(() => {
    setPageNumber(1);

    return {
      ...searchParams,
      ...filterParams,
      ...sortParams,
      byCountry:
        filterParams.byCountry === '' ? undefined : filterParams.byCountry,
      byStructureVolumeTo:
        filterParams.byStructureVolumeTo === Infinity
          ? undefined
          : filterParams.byStructureVolumeTo,
      fitlineClubMembers: booleanOrUndefined(filterParams.fitlineClubMembers),
      newRegistrations: booleanOrUndefined(filterParams.newRegistrations),
      withCheckAssurance: booleanOrUndefined(filterParams.withCheckAssurance),
      withNewQualification: booleanOrUndefined(
        filterParams.withNewQualification
      ),
      withSponsorActivity: booleanOrUndefined(filterParams.withSponsorActivity)
    };
  }, [filterParams, searchParams, sortParams]);

  const {
    data: searchResults,
    isLoading,
    error
  } = useUsersSearch(pageNumber, ITEMS_PER_PAGE, searchQuery);

  const onPageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  return (
    <section className="bg-white shadow-md rounded-md py-sm">
      {isLoading && (
        <div className="w-full py-md flex items-center justify-center">
          <LoadingSpinner smallSize />
        </div>
      )}

      {!isLoading && error && (
        <div className="w-full min-h-[300px] flex items-center justify-center">
          <ErrorHandler err={error} />
        </div>
      )}

      {!_.isUndefined(searchResults) && (
        <div className="flex justify-end pointer-events-auto">
          <Pagination
            currentPage={searchResults.pageNumber}
            onPageChange={onPageChange}
            totalPages={searchResults.totalPages}
          />
        </div>
      )}

      {!_.isUndefined(searchResults) && (
        <>
          {searchResults.data.length > 0 && (
            <>
              <SearchPresenterDesktopView {...searchResults} />
              <SearchPresenterMobileView {...searchResults} />
            </>
          )}
          {searchResults.data.length === 0 && (
            <div className="flex flex-col items-center justify-center text-silver gap-sm py-md">
              <Icon name={IconName.Search} size={48} />
              {t('No results found for the selected filters.')}
            </div>
          )}
        </>
      )}
    </section>
  );
}

function SearchPresenterDesktopView(props: IUserSearchResults) {
  return (
    <div className="hidden md:block overflow-auto pt-sm">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <td></td>
            <td className="pr-xs pmi-caption text-silver uppercase text-center">
              <CheckAssuranceHeader />
            </td>
            <td className="pr-xs pmi-caption text-silver uppercase text-center">
              <CountryHeader />
            </td>
            <td className="pr-xs pmi-caption text-silver uppercase text-center">
              <PreviousQualificationHeader />
            </td>
            <td className="pr-xs pmi-caption text-silver uppercase text-center">
              <FitlineClubMemberHeader />
            </td>
            <td className="pr-xs pmi-caption text-silver uppercase text-center">
              <AutoshipHeader />
            </td>
            <td className="pr-xs pmi-caption text-silver uppercase text-right">
              <PersonalSalesHeader />
            </td>
            <td className="pr-xs pmi-caption text-silver uppercase text-right">
              <SixLevelSalesHeader />
            </td>
            <td className="pr-xs pmi-caption text-silver uppercase text-right">
              <StructureSalesHeader />
            </td>
          </tr>
        </thead>
        <tbody>
          {props.data.map(user => {
            return (
              <tr key={user.userId} className="even:bg-[#f6f6f7]">
                <td className="p-sm">
                  <UserHeader
                    user={user}
                    showBadges
                    showCheckAssurance
                    showQualification
                    showNewRegistration
                  />
                </td>
                <td className="pr-xs text-center">
                  <CheckAssuranceData {...user} />
                </td>
                <td className="pr-xs text-center">
                  <CountryData {...user} />
                </td>
                <td className="pr-xs text-center">
                  <PreviousQualificationData {...user} />
                </td>
                <td className="pr-xs text-center">
                  <FitlineClubMemberData {...user} />
                </td>
                <td className="pr-xs text-center">
                  <AutoshipData {...user} />
                </td>
                <td className="pr-xs text-right">
                  <PersonalSalesData {...user} />
                </td>
                <td className="pr-xs text-right">
                  <SixLevelSalesData {...user} />
                </td>
                <td className="p-sm text-right">
                  <StructureSalesData {...user} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

type TSelectedColumn =
  | 'previous-qualification'
  | 'autoship'
  | 'personal-sales'
  | 'six-level-sales'
  | 'structure-sales'
  | 'country'
  | 'check-assurance'
  | 'fitline-club-member';

function SearchPresenterMobileView(props: IUserSearchResults) {
  const { t } = useTranslation();
  const { sortParams } = useAnalyticsContext();

  const defaultSelectedColumn: TSelectedColumn = (() => {
    switch (sortParams.sorting) {
      case TUserSearchSorting.ByHasCheckAssuranceDesc:
      case TUserSearchSorting.ByHasCheckAssuranceAsc:
        return 'check-assurance';
      case TUserSearchSorting.ByQualificationLastMonthDesc:
      case TUserSearchSorting.ByQualificationLastMonthAsc:
        return 'previous-qualification';
      case TUserSearchSorting.ByOwnVolumeDesc:
      case TUserSearchSorting.ByOwnVolumeAsc:
        return 'personal-sales';
      case TUserSearchSorting.BySixLevelVolumeDesc:
      case TUserSearchSorting.BySixLevelVolumeAsc:
        return 'six-level-sales';
      case TUserSearchSorting.ByQualificationDesc:
      case TUserSearchSorting.ByQualificationAsc:
      case TUserSearchSorting.ByRankDesc:
      case TUserSearchSorting.ByRankAsc:
      case TUserSearchSorting.BySponsorActivityDesc:
      case TUserSearchSorting.BySponsorActivityAsc:
      case TUserSearchSorting.ByStructureVolumeDesc:
      case TUserSearchSorting.ByStructureVolumeAsc:
      case TUserSearchSorting.ByNameAsc:
      case TUserSearchSorting.ByNameDesc:
      default:
        return 'structure-sales';
    }
  })() as TSelectedColumn;

  const [selectedColumn, setSelectedColumn] = useState<TSelectedColumn>(
    defaultSelectedColumn
  );

  return (
    <div className="flex md:hidden flex-col items-end space-y-xs">
      <div className="mt-sm mr-sm relative w-fit">
        <div className="border border-primary text-primary inline-flex items-center flex-nowrap gap-xs rounded-md p-xs w-fit pmi-caption uppercase">
          {selectedColumn === 'previous-qualification' && (
            <PreviousQualificationHeader />
          )}
          {selectedColumn === 'fitline-club-member' && (
            <FitlineClubMemberHeader />
          )}
          {selectedColumn === 'autoship' && <AutoshipHeader />}
          {selectedColumn === 'personal-sales' && <PersonalSalesHeader />}
          {selectedColumn === 'six-level-sales' && <SixLevelSalesHeader />}
          {selectedColumn === 'structure-sales' && <StructureSalesHeader />}
          {selectedColumn === 'check-assurance' && <CheckAssuranceHeader />}
          {selectedColumn === 'country' && <CountryHeader />}
          <Icon name={IconName.ChevronDown} size={16} />
        </div>
        <select
          className="absolute inset-0 appearance-none cursor-pointer opacity-0 w-full"
          title="Select property to display"
          onChange={event =>
            setSelectedColumn(event.target.value as typeof selectedColumn)
          }
          value={selectedColumn}
        >
          <option value={'check-assurance'}>{t('Check Assurance')}</option>
          <option value={'country'}>{t('Country')}</option>
          <option value={'previous-qualification'}>
            {t('Last month qualification')}
          </option>
          <option value={'fitline-club-member'}>
            {t('FitLine Club member')}
          </option>
          <option value={'autoship'}>{t('Autoship')}</option>
          <option value={'personal-sales'}>{t('Personal Sales')}</option>
          <option value={'six-level-sales'}>{t('6-Level Sales')}</option>
          <option value={'structure-sales'}>{t('Structure Sales')}</option>
        </select>
      </div>

      <ul className="w-full">
        {props.data.map(user => {
          return (
            <li key={user.userId} className="p-sm even:bg-[#f6f6f7]">
              <Disclosure>
                {({ open }) => (
                  <>
                    <div className="flex flex-row flex-nowrap gap-sm">
                      <div className="flex flex-wrap items-center justify-between gap-xs w-full">
                        <UserHeader
                          user={user}
                          showBadges
                          showCheckAssurance
                          showQualification
                          showNewRegistration
                        />
                        <div className="float-right flex flex-1 justify-end">
                          {selectedColumn === 'previous-qualification' && (
                            <PreviousQualificationData {...user} />
                          )}
                          {selectedColumn === 'fitline-club-member' && (
                            <FitlineClubMemberData {...user} />
                          )}
                          {selectedColumn === 'autoship' && (
                            <AutoshipData {...user} />
                          )}
                          {selectedColumn === 'personal-sales' && (
                            <PersonalSalesData {...user} />
                          )}
                          {selectedColumn === 'six-level-sales' && (
                            <SixLevelSalesData {...user} />
                          )}
                          {selectedColumn === 'structure-sales' && (
                            <StructureSalesData {...user} />
                          )}
                          {selectedColumn === 'check-assurance' && (
                            <CheckAssuranceData {...user} />
                          )}
                          {selectedColumn === 'country' && (
                            <CountryData {...user} />
                          )}
                        </div>
                      </div>
                      <Disclosure.Button className="px-xs">
                        {open ? (
                          <Icons.Common.ChevronUp
                            size={20}
                            className="text-active"
                          />
                        ) : (
                          <Icons.Common.ChevronDown
                            size={20}
                            className="text-active"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <Disclosure.Panel className="py-sm space-y-xs">
                      <div className="flex flex-wrap justify-between">
                        <span className="pmi-caption text-silver uppercase">
                          <CheckAssuranceHeader />
                        </span>
                        <CheckAssuranceData {...user} />
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <span className="pmi-caption text-silver uppercase">
                          <CountryHeader />
                        </span>
                        <CountryData {...user} />
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <span className="pmi-caption text-silver uppercase">
                          <PreviousQualificationHeader />
                        </span>
                        <PreviousQualificationData {...user} />
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <span className="pmi-caption text-silver uppercase">
                          <FitlineClubMemberHeader />
                        </span>
                        <FitlineClubMemberData {...user} />
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <span className="pmi-caption text-silver uppercase">
                          <AutoshipHeader />
                        </span>
                        <AutoshipData {...user} />
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <span className="pmi-caption text-silver uppercase">
                          <PersonalSalesHeader />
                        </span>
                        <PersonalSalesData {...user} />
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <span className="pmi-caption text-silver uppercase">
                          <SixLevelSalesHeader />
                        </span>
                        <SixLevelSalesData {...user} />
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <span className="pmi-caption text-silver uppercase">
                          <StructureSalesHeader />
                        </span>
                        <StructureSalesData {...user} />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function PreviousQualificationHeader() {
  const { t } = useTranslation();

  return (
    <span className="inline-flex items-center gap-xs justify-center truncate px-xs">
      <FeatureIcon name={FeatureIconName.PreviousQualification} size={16} />
      {t('Last month qualification')}
    </span>
  );
}

function PreviousQualificationData(user: IUserProfile) {
  if (
    _.isUndefined(user.businessDataSummary) ||
    _.isUndefined(user.businessDataSummary.qualificationLastMonth)
  ) {
    return null;
  }

  return (
    <span className="inline-block">
      <QualificationBadge
        qualification={user.businessDataSummary.qualificationLastMonth}
        previousMonth
      />
    </span>
  );
}

function AutoshipHeader() {
  const { t } = useTranslation();

  return (
    <span className="inline-flex items-center gap-xs justify-center truncate px-xs">
      <FeatureIcon name={FeatureIconName.Autoships} size={16} />
      {t('Autoship')}
    </span>
  );
}

function AutoshipData(user: IUserProfile) {
  return <AutoshipsColumn userProfile={user} />;
}

function PersonalSalesHeader() {
  const { t } = useTranslation();

  return (
    <span className="inline-flex items-center gap-xs justify-end truncate px-xs">
      <FeatureIcon name={FeatureIconName.PersonalSales} size={16} />
      {t('Personal Sales')}
    </span>
  );
}

function PersonalSalesData(user: IUserProfile) {
  if (
    _.isUndefined(user.businessDataSummary) ||
    _.isUndefined(user.businessDataSummary.ownVolume)
  ) {
    return null;
  }

  return (
    <FormattedUnitValue
      unit="points"
      value={user.businessDataSummary.ownVolume}
    />
  );
}

function SixLevelSalesHeader() {
  const { t } = useTranslation();

  return (
    <span className="inline-flex items-center gap-xs truncate px-xs">
      <FeatureIcon name={FeatureIconName.SixLevelSales} size={16} />
      {t('6-Level Sales')}
    </span>
  );
}

function SixLevelSalesData(user: IUserProfile) {
  if (
    _.isUndefined(user.businessDataSummary) ||
    _.isUndefined(user.businessDataSummary.sixLevelVolume)
  ) {
    return null;
  }

  return (
    <FormattedUnitValue
      unit="points"
      value={user.businessDataSummary.sixLevelVolume}
    />
  );
}

function StructureSalesHeader() {
  const { t } = useTranslation();

  return (
    <span className="inline-flex items-center gap-xs truncate px-xs">
      <FeatureIcon name={FeatureIconName.StructureSales} size={16} />
      {t('Structure Sales')}
    </span>
  );
}

function StructureSalesData(user: IUserProfile) {
  if (
    _.isUndefined(user.businessDataSummary) ||
    _.isUndefined(user.businessDataSummary.structureVolume)
  ) {
    return null;
  }

  return (
    <div className="inline-flex flex-row items-center">
      <TrendValue
        style="illustration-only"
        value={computePercentageTrend(
          user.businessDataSummary.structureVolume.current,
          user.businessDataSummary.structureVolume.lastMonth
        )}
      />
      <FormattedUnitValue
        unit="points"
        value={user.businessDataSummary.structureVolume.current}
      />
    </div>
  );
}

function CountryHeader() {
  const { t } = useTranslation();

  return (
    <span className="inline-flex items-center gap-xs truncate px-xs">
      <BiWorld size={16} />
      {t('Country')}
    </span>
  );
}

function CountryData(user: IUserProfile) {
  const { pii } = usePII();

  if (_.isUndefined(user.countryCode)) {
    return null;
  }

  return (
    <div className="inline-flex flex-row items-center">
      <CountryFlag countryCode={pii(user.countryCode, 'country')} />
    </div>
  );
}

function CheckAssuranceHeader() {
  const { t } = useTranslation();

  return (
    <span className="inline-flex items-center gap-xs truncate px-xs">
      <FeatureIcon name={FeatureIconName.CheckAssurance} size={16} />
      {t('Check Assurance')}
    </span>
  );
}

function CheckAssuranceData(user: IUserProfile) {
  if (
    _.isUndefined(user.businessDataSummary) ||
    _.isUndefined(user.businessDataSummary.hasCheckAssurance)
  ) {
    return null;
  }

  return (
    <div className="inline-flex flex-row items-center">
      {user.businessDataSummary?.hasCheckAssurance ? (
        <Icon name={IconName.Shield} size={16} className="text-success" />
      ) : (
        <Icons.Common.Cancel size={20} className="text-error" />
      )}
    </div>
  );
}

function FitlineClubMemberHeader() {
  const { t } = useTranslation();

  return (
    <span className="inline-flex items-center gap-xs truncate px-xs">
      <FeatureIcon name={FeatureIconName.FitlineClubMember} size={16} />
      {t('FitLine Club member')}
    </span>
  );
}

function FitlineClubMemberData(user: IUserProfile) {
  return (
    <div className="inline-flex flex-row items-center">
      {user.isFitlineClubMember && (
        <PiSealCheckFill size={20} className="text-success" />
      )}
    </div>
  );
}
