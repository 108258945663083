import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { useGlobalConfigsContext } from 'contexts';
import { CreateNewBasketButton } from 'features/baskets/components/create-basket-button/CreateNewBasketButton';
import { Navigate, Outlet } from 'react-router-dom';

export function SalesRoute() {
  const { promoShop } = useGlobalConfigsContext();

  if (!promoShop || !promoShop.id) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <PageTitle />
      <div className="flex flex-row justify-end">
        <CreateNewBasketButton />
      </div>
      <br />
      <Outlet />
    </>
  );
}
