import {
  CoachBadge,
  QualificationBadge,
  SponsorBadge,
  NewRegistrationBadge
} from 'components/badges';
import { RankBadge } from 'components/badges/qualification/RankBadge';
import { UserAvatar } from 'components/icons';
import { useGlobalConfigsContext } from 'contexts';
import { PII, usePII } from 'features/pii';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { IUser, IUserProfile, IUserProfileSimplified } from 'types';

interface IUserHeaderProps {
  readonly invertAvatarColors?: boolean;
  readonly user: IUserProfileSimplified | IUserProfile | IUser;
  readonly showBadges: boolean;
  readonly showQualification: boolean;
  readonly showCheckAssurance: boolean;
  readonly showNewRegistration?: boolean;
  readonly disableNavigation?: boolean;
}

export function UserHeader(props: IUserHeaderProps) {
  const { showNewRegistration = false } = props;

  const { t } = useTranslation();
  const { pii } = usePII();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();
  const isCoach =
    'coach' in props.user &&
    'sponsor' in props.user &&
    !_.isUndefined(props.user.coach) &&
    _.isEqual(props.user.coach.userId, props.user.sponsor.userId);
  const isSponsor =
    'coach' in props.user && !_.isUndefined(props.user.coach) && !isCoach;

  const {
    name,
    userId,
    rank,
    qualification,
    isDiscountCustomer,
    isTeampartner,
    isProspect,
    hasSponsorActivity,
    isNewRegistration
  } = useMemo(() => {
    if ('name' in props.user) {
      return {
        name: props.user.name,
        userId: props.user.userId,
        rank: props.user.rank,
        qualification: props.user.qualification,
        isDiscountCustomer: props.user.isDiscountCustomer,
        isTeampartner: props.user.isTeampartner,
        hasSponsorActivity: props.user.hasSponsorActivity,
        isNewRegistration: props.user.isNewRegistration,
        isProspect: false
      };
    }

    return {
      name:
        props.user.type === 'Prospect'
          ? props.user.emails[0].email
          : `${props.user.givenName} ${props.user.familyName}`,
      userId: props.user.publicId,
      rank: undefined,
      qualification: undefined,
      isDiscountCustomer:
        props.user.type === 'BusinessBuilder' &&
        props.user.classification.includes('DiscountCustomer'),
      isTeampartner: props.user.type === 'BusinessBuilder',
      isProspect: props.user.type === 'Prospect',
      hasSponsorActivity: false,
      isNewRegistration: false
    };
  }, [props.user]);

  const badges = useMemo(() => {
    return (
      <>
        {props.showQualification && !_.isUndefined(rank) && (
          <RankBadge rank={rank} />
        )}
        {props.showQualification && !_.isUndefined(qualification) && (
          <QualificationBadge qualification={qualification} currentMonth />
        )}
        {isCoach && <CoachBadge coachName={props.user.coach.name} />}
        {isSponsor && <SponsorBadge sponsorName={props.user.sponsor.name} />}
      </>
    );
  }, [
    isCoach,
    isSponsor,
    props.showQualification,
    props.user,
    qualification,
    rank
  ]);

  const headerContent = (
    <>
      <div
        className={`mt-[5px] w-fit h-fit rounded-full relative ${
          hasSponsorActivity ? 'ring-2 ring-success' : ''
        }`}
      >
        <UserAvatar
          userName={name}
          userType={
            isDiscountCustomer
              ? 'discount-customer'
              : isTeampartner
              ? 'teampartner'
              : isProspect
              ? 'prospect'
              : 'customer'
          }
        />

        {showNewRegistration && isNewRegistration && (
          <div className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2">
            <NewRegistrationBadge />
          </div>
        )}

        {props.showCheckAssurance &&
          'businessDataSummary' in props.user &&
          props.user.businessDataSummary?.hasCheckAssurance && (
            <span
              title={t('Achieved the Check Assurance!')}
              className="absolute -top-[2px] -left-[6px] rounded-full bg-white p-[2px]"
            >
              <Icon name={IconName.Shield} size={10} className="text-success" />
            </span>
          )}
      </div>
      <div className="ml-xs text-left min-w-[150px]">
        <PII value={name} type="name" />
        <div className="flex flex-row gap-xs">
          <p className="pmi-caption text-silver">
            {userId ? (
              <>
                #<PII value={userId} type="tpid" />
              </>
            ) : (
              t('Pending user resgistration')
            )}
          </p>
          {props.showBadges && badges}
        </div>
      </div>
    </>
  );

  if (props.disableNavigation) {
    return <div className="flex flex-row">{headerContent}</div>;
  }

  return (
    <NavLink to={`/downline/pathTo?userId=${userId}`} title={pii(name, 'name')}>
      <div
        className={`flex flex-row ${
          supportsHover
            ? 'hover:bg-secondary hover:rounded hover:text-active hover:p-xxs hover:-m-xxs'
            : ''
        }`}
      >
        {headerContent}
      </div>
    </NavLink>
  );
}
