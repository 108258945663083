import { InvoiceProductList } from 'features/invoices';
import _ from 'lodash';
import { IBasket } from 'types';

export function BasketProducts(props: IBasket) {
  return (
    <InvoiceProductList
      items={props.basket.items.map(i => {
        const imgSrc = i.details.media.find(
          m => m.type === 'image' && !_.isUndefined(m.url)
        );

        return {
          articleNumber: i.articleNumber,
          currencyCode: i.details.currencyCode,
          itemGross: i.details.basePrice,
          points: 0, // Hardcoded points because customers never get points!
          quantity: i.quantity,
          name: i.details.attributes.name?.value,
          imgSrc: imgSrc?.url
        };
      })}
    />
  );
}
