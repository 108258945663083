import { NewBasketContextProvider } from 'features/baskets/components/basket-creation-wizard/NewBasketContext';
import { NewBasketWizard } from 'features/baskets/components/basket-creation-wizard/NewBasketWizard';

export function CreateBasketRoute() {
  return (
    <NewBasketContextProvider>
      <NewBasketWizard />
    </NewBasketContextProvider>
  );
}
