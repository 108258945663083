import { IIconProps } from '../types/IIconProps';

export function FlagOutlineIcon(props: IIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={props.size}
      height={props.size}
      className={props.className}
    >
      <g fill="currentColor" stroke="currentColor" strokeWidth="1">
        <polyline
          fill="none"
          points="2.5 1.5 13.5 1.5 11.5 5.5 13.5 9.5 2.5 9.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="2.5"
          x2="2.5"
          y1="0.5"
          y2="15.5"
        />
      </g>
    </svg>
  );
}
