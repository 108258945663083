import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { IS_PWA } from 'contants';
import { useGlobalConfigsContext } from 'contexts';
import { PromoShopBanner } from 'features/promo-shop';
import { VirtualWarehouseHelpModal } from 'features/virtual-warehouse/components/VirtualWarehouseHelpModal';
import { FAQButton } from 'libs/cms';
import { Navigate, Outlet } from 'react-router-dom';

export function VirtualWarehouseRoute() {
  const { promoShop } = useGlobalConfigsContext();

  if (!promoShop || !promoShop.isVirtualWarehouseEnabled) {
    return <Navigate to="/" replace />;
  }

  return (
    <section className="space-y-md">
      <div className="flex justify-between">
        <PageTitle />
        <VirtualWarehouseHelpModal />
      </div>
      <PromoShopBanner />
      <div
        className={
          IS_PWA
            ? 'fixed right-[16px] bottom-[72px] pmi-safe-area-inset-b pmi-safe-area-inset-r'
            : 'fixed right-sm bottom-sm md:right-lg md:bottom-lg'
        }
      >
        <FAQButton />
      </div>
      <Outlet />
    </section>
  );
}
