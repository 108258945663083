import { PillBadge } from 'components/badges';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import {
  TbProgress,
  TbProgressAlert,
  TbProgressCheck,
  TbProgressX
} from 'react-icons/tb';
import { BasketStatus } from 'types';

interface IBasketStatusBadgeProps {
  readonly status: BasketStatus;
  readonly small?: boolean;
}

export function BasketStatusBadge(props: IBasketStatusBadgeProps) {
  const { t } = useTranslation();

  if (props.status === BasketStatus.Canceled) {
    return (
      <PillBadge
        title={t('Canceled')}
        className="text-silver bg-lightGray font-bold uppercase"
        icon={<TbProgressX size={20} />}
        small={props.small}
      />
    );
  }

  if (props.status === BasketStatus.PaymentAuthorized) {
    return (
      <PillBadge
        title={t('Sold')}
        className="text-white bg-success font-bold uppercase"
        icon={<TbProgressCheck size={20} />}
        small={props.small}
      />
    );
  }

  if (props.status === BasketStatus.PaymentPending) {
    return (
      <PillBadge
        title={t('Awaiting Payment')}
        className="text-white bg-warn font-bold uppercase"
        icon={<TbProgressAlert size={20} />}
        small={props.small}
      />
    );
  }

  return (
    <PillBadge
      title={t('Created')}
      className="text-white bg-active font-bold uppercase"
      icon={<TbProgress size={20} />}
      small={props.small}
    />
  );
}
