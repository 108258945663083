import { useTranslation } from 'libs/translations';
import type { CountdownRenderProps } from 'react-countdown';
import { padWithZeros } from 'utils';

export function CountdownRenderer(props: CountdownRenderProps) {
  const { t } = useTranslation();

  const { days, hours, minutes, seconds } = props;
  const paddedHours = padWithZeros(hours, 2);
  const paddedMinutes = padWithZeros(minutes, 2);
  const paddedSeconds = padWithZeros(seconds, 2);

  return (
    <>
      {days > 0 &&
        t('{{days}} days {{hours}}:{{minutes}}:{{seconds}}', {
          days: '' + days,
          hours: paddedHours,
          minutes: paddedMinutes,
          seconds: paddedSeconds
        })}

      {days === 0 && `${paddedHours}:${paddedMinutes}:${paddedSeconds}`}
    </>
  );
}
