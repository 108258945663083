import { FormattedUnitValue } from 'components/text';
import {
  InvoiceSectionDivider,
  InvoiceCosts,
  InvoiceCostItem,
  InvoiceCostTotal
} from 'features/invoices';
import { useTranslation } from 'libs/translations';
import { ICommitment } from 'types';

export function CommitmentCosts(props: ICommitment) {
  const { t } = useTranslation();

  return (
    <InvoiceCosts>
      <InvoiceCostItem
        label={t('Subtotal')}
        value={
          <FormattedUnitValue
            unit={props.totals.currencyCode}
            value={props.totals.itemsGross - props.totals.itemsTaxAmount}
          />
        }
      />
      <InvoiceCostItem
        label={t('Shipping & Handling')}
        value={
          <FormattedUnitValue
            unit={props.totals.currencyCode}
            value={props.totals.deliveryGross}
          />
        }
      />
      {props.totals.additionalChargesGross > 0 && (
        <InvoiceCostItem
          label={t('Additional charges')}
          value={
            <FormattedUnitValue
              unit={props.totals.currencyCode}
              value={props.totals.additionalChargesGross}
            />
          }
        />
      )}
      <InvoiceCostItem
        label={t('Tax')}
        value={
          <FormattedUnitValue
            unit={props.totals.currencyCode}
            value={props.totals.totalTaxAmount}
          />
        }
      />

      <InvoiceSectionDivider thick />
      <InvoiceCostTotal
        label={t('Total')}
        value={
          <span className="font-bold text-xl">
            <FormattedUnitValue
              unit={props.totals.currencyCode}
              value={props.totals.totalGross}
            />
          </span>
        }
      />
      <InvoiceCostTotal
        label={t('Points')}
        value={<FormattedUnitValue unit="points" value={props.totals.points} />}
      />
    </InvoiceCosts>
  );
}
