import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getEventStory } from 'services';

import { IEventStory } from './IEventStory';

export function useEventStory(
  eventId: string,
  eventSlug: string | undefined,
  language: string | undefined
) {
  const [eventStory, setEventStory] = useState<IEventStory>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (_.isUndefined(eventSlug) || _.isUndefined(language)) {
      return;
    }

    setLoading(true);
    getEventStory(eventId, eventSlug, language)
      .then(setEventStory)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [eventId, eventSlug, language]);

  return {
    eventStory,
    isLoading,
    error
  };
}
