import { IProspectCreationProps } from 'types';

import { execGet, execPost } from '../RequestUtils';

export async function createProspect(
  prospect: IProspectCreationProps
): Promise<string> {
  const { countryCode, sponsorId, ...requestBody } = prospect;
  const searchParams = new URLSearchParams();
  searchParams.set('sponsor', sponsorId);
  searchParams.set('countryCode', countryCode);

  const { id } = await execPost<{ readonly id: string }>(
    `${
      process.env.REACT_APP_USER_MANAGEMENT_API
    }/prospects/customer/start?${searchParams.toString()}`,
    requestBody
  );

  return id;
}

export async function getProspectFormSchema(countryId: string) {
  const response = await execGet<{
    jsonSchemas: {
      CustomerBootstrap: {
        jsonSchemaContent: string;
        uiSchemaContent: string;
      };
    };
    // ...
  }>(
    `${
      process.env.REACT_APP_USER_MANAGEMENT_API
    }/registration/configuration?country=${countryId.toLowerCase()}&type=customer`
  );

  return {
    uiSchema: response.jsonSchemas.CustomerBootstrap.uiSchemaContent,
    schema: response.jsonSchemas.CustomerBootstrap.jsonSchemaContent
  };
}
