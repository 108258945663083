import { useQuery } from '@tanstack/react-query';
import { BottomSheetContainer } from 'components/modals';
import { PII } from 'features/pii';
import { fetchUserProfile } from 'services';

import { UserAvatar } from './icons';
import { ProfileDetails } from './ProfileDetails';
import { LoadingSpinner } from './spinners';

interface IProfileProps {
  readonly open: boolean;
  readonly onDismiss: () => void;
  readonly userId: string;
}

function useOfficeUserProfile(userId: string) {
  const query = useQuery({
    queryKey: ['pmi.web.office__office_user_profile', userId],
    queryFn: () => fetchUserProfile(userId),
    staleTime: 900000,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}

export function Profile(props: IProfileProps) {
  const { data: userProfile, isLoading } = useOfficeUserProfile(props.userId);

  return (
    <BottomSheetContainer
      open={props.open}
      onDismiss={props.onDismiss}
      header={
        isLoading || !userProfile ? null : (
          <div
            className={`bg-secondary px-sm md:px-md py-md md:py-lg flex flex-col items-center`}
          >
            <div className="flex flex-row flex-nowrap gap-xs">
              <UserAvatar
                userName={userProfile.name}
                userType={
                  userProfile.isDiscountCustomer
                    ? 'discount-customer'
                    : userProfile.isTeampartner
                    ? 'teampartner'
                    : 'customer'
                }
                huge
              />
              <div>
                <h1>
                  <PII type="name" value={userProfile.name} />
                </h1>
                <h2>
                  #<PII type="ids" value={userProfile.userId} />
                </h2>
              </div>
            </div>
          </div>
        )
      }
    >
      <section>
        {isLoading && (
          <div className="w-full flex justify-center p-md">
            <LoadingSpinner smallSize />
          </div>
        )}
        {!isLoading && userProfile && <ProfileDetails {...userProfile} />}
      </section>
    </BottomSheetContainer>
  );
}
