import { localizedCountryName } from '@pmi.web/countries';
import { RankBadge } from 'components/badges/qualification/RankBadge';
import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { QUALIFICATIONS } from 'contants';
import { AccountBackButton } from 'features/account';
import { PII, usePII } from 'features/pii';
import { CountryFlag, Icons } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { GoLinkExternal } from 'react-icons/go';
// eslint-disable-next-line no-restricted-imports
import { PiSealCheckFill } from 'react-icons/pi';
import AuthenticationService from 'services/AuthenticationService';

export function AccountProfileRoute() {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  const authenticatedUserProfile = AuthenticationService.user;

  return (
    <>
      <PageTitle />
      <section className="space-y-md m-auto">
        <AccountBackButton />

        <section className="grid grid-cols-1 lg:grid-cols-2 gap-md">
          <div>
            <div className="flex justify-between gap-3 items-center">
              <h2 className="text-primary">{t('Personal information')}</h2>
              <a
                href={'' + process.env.REACT_APP_LINK_EDIT_PROFILE}
                target="_blank"
                rel="noreferrer"
                className="flex flex-row flex-nowrap gap-xs items-center w-fit text-primary hover:text-active"
              >
                <span>{t('Edit')}</span>
                <GoLinkExternal size={20} />
              </a>
            </div>
            <div className="bg-white p-sm shadow-md rounded-md mt-sm">
              <div className="grid grid-cols-[140px_minmax(100px,_1fr)] gap-sm">
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('Name')}
                </h3>
                <p>
                  <PII value={authenticatedUserProfile?.name} type="name" />
                </p>
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('Birth Date')}
                </h3>
                <p>
                  {!_.isUndefined(authenticatedUserProfile) &&
                    !_.isUndefined(authenticatedUserProfile.birthDate) && (
                      <PII
                        value={authenticatedUserProfile.birthDate.toLocaleString(
                          activeLanguage,
                          'long'
                        )}
                        type="fixed-date"
                      />
                    )}
                </p>
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('Phone number')}
                </h3>
                <p>
                  <PII
                    value={authenticatedUserProfile?.phoneNumber}
                    type="phone"
                  />
                </p>
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('Email')}
                </h3>
                <p className="text-ellipsis overflow-hidden">
                  <PII value={authenticatedUserProfile?.email} type="email" />
                </p>
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('Address')}
                </h3>
                <div>
                  {!_.isUndefined(authenticatedUserProfile) &&
                    !_.isUndefined(authenticatedUserProfile.address) && (
                      <>
                        {!_.isUndefined(
                          authenticatedUserProfile.address.street
                        ) && (
                          <p>
                            <PII
                              value={authenticatedUserProfile.address.street}
                              type="address"
                            />
                          </p>
                        )}
                        {!_.isUndefined(
                          authenticatedUserProfile.address.zipcode
                        ) && (
                          <p>
                            <PII
                              value={authenticatedUserProfile.address.zipcode}
                              type="address"
                            />
                          </p>
                        )}
                        {!_.isUndefined(
                          authenticatedUserProfile.address.city
                        ) && (
                          <p>
                            <PII
                              value={authenticatedUserProfile.address.city}
                              type="address"
                            />
                          </p>
                        )}
                        {!_.isUndefined(
                          authenticatedUserProfile.address.countryCode
                        ) && (
                          <p className="text-ellipsis overflow-hidden">
                            {localizedCountryName(
                              pii(
                                authenticatedUserProfile.address.countryCode,
                                'country'
                              ),
                              activeLanguage
                            )}
                          </p>
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-primary">{t('Team Partner')}</h2>
            <div className="bg-white p-sm shadow-md rounded-md mt-sm">
              <div className="grid grid-cols-[140px_minmax(100px,_1fr)] gap-sm">
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('ID')}
                </h3>
                <p>
                  <PII value={authenticatedUserProfile?.userId} type="tpid" />
                </p>
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('Rank')}
                </h3>
                <div className="flex flex-wrap items-center">
                  {!_.isUndefined(authenticatedUserProfile) &&
                    !_.isUndefined(authenticatedUserProfile.rank) && (
                      <span>
                        <RankBadge rank={authenticatedUserProfile.rank} />
                        <span className="ml-xs">
                          {QUALIFICATIONS[authenticatedUserProfile.rank].name}
                        </span>
                      </span>
                    )}
                </div>
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('Registration Date')}
                </h3>
                <p>
                  {!_.isUndefined(authenticatedUserProfile) &&
                    !_.isUndefined(
                      authenticatedUserProfile.registrationDate
                    ) && (
                      <PII
                        value={authenticatedUserProfile.registrationDate.toLocaleString(
                          activeLanguage,
                          'long'
                        )}
                        type="fixed-date"
                      />
                    )}
                </p>
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('Country')}
                </h3>
                <p>
                  {!_.isUndefined(authenticatedUserProfile) &&
                    !_.isUndefined(authenticatedUserProfile.countryCode) && (
                      <CountryFlag
                        countryCode={pii(
                          authenticatedUserProfile.countryCode,
                          'country'
                        )}
                        renderLocalizedCountryName
                      />
                    )}
                </p>
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('FitLine Club member')}
                </h3>
                <div className="flex flex-wrap items-center">
                  {authenticatedUserProfile.isFitlineClubMember ? (
                    <PiSealCheckFill size={20} className="text-success" />
                  ) : (
                    <Icons.Common.Cancel size={20} className="text-error" />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-primary">{t('Sponsor information')}</h2>
            <div className="bg-white p-sm shadow-md rounded-md mt-sm">
              <div className="grid grid-cols-[140px_minmax(100px,_1fr)] gap-sm">
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('Sponsor Name')}
                </h3>
                <p>
                  <PII
                    value={authenticatedUserProfile?.sponsor.name}
                    type="name"
                  />
                </p>
                <h3 className="text-silver overflow-hidden text-ellipsis">
                  {t('Sponsor ID')}
                </h3>
                <p>
                  <PII
                    value={authenticatedUserProfile?.sponsor.userId}
                    type="tpid"
                  />
                </p>
              </div>
            </div>
          </div>

          {!_.isUndefined(authenticatedUserProfile) &&
            !_.isUndefined(authenticatedUserProfile.coach) && (
              <div>
                <h2 className="text-primary">{t('Coach information')}</h2>
                <div className="bg-white p-sm shadow-md rounded-md mt-sm">
                  <div className="grid grid-cols-[140px_minmax(100px,_1fr)] gap-sm">
                    <h3 className="text-silver overflow-hidden text-ellipsis">
                      {t('Coach Name')}
                    </h3>
                    <p>
                      <PII
                        value={authenticatedUserProfile.coach.name}
                        type="name"
                      />
                    </p>
                    <h3 className="text-silver overflow-hidden text-ellipsis">
                      {t('Coach ID')}
                    </h3>
                    <p>
                      <PII
                        value={authenticatedUserProfile.coach.userId}
                        type="tpid"
                      />
                    </p>
                  </div>
                </div>
              </div>
            )}
        </section>
      </section>
    </>
  );
}
