import { ProductsInBasketSummary } from 'features/baskets/FullscreenWizardLayout2';
import { BasketOwner } from 'features/virtual-warehouse';
import { useBoolean } from 'hooks';
import { Timestamp } from 'libs/time';
import { useTranslation } from 'libs/translations';
import { ReactNode, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { IoAddOutline, IoCheckmark, IoPencilSharp } from 'react-icons/io5';

import { WizardStepId, useNewBasketContext } from './NewBasketContext';

interface IWizarSectionProps {
  readonly step: WizardStepId;
  readonly title: string;
  readonly children: ReactNode;
  readonly active: boolean;
  readonly completed: boolean;
  readonly index: number;
  readonly onEdit?: () => void;
  readonly editOnDetails?: boolean;
  readonly headerActiveContent?: ReactNode;
}

export function WizardSection({
  step,
  title,
  children,
  active,
  completed,
  index,
  onEdit,
  editOnDetails,
  headerActiveContent
}: IWizarSectionProps) {
  const { t } = useTranslation();

  const [
    isEditingDetails,
    { setFalse: stopEditingDetails, setTrue: startEditingDetails }
  ] = useBoolean(false);

  useEffect(() => {
    stopEditingDetails();
  }, [completed, stopEditingDetails]);

  return (
    <div className="bg-white h-fit rounded-md shadow-md">
      <div
        className={`p-sm rounded-md bg-white flex flex-nowrap gap-sm justify-between items-center ${
          active ? 'sticky top-0 z-1' : ''
        }`}
      >
        <h2 className="flex items-center line-clamp-1 gap-sm">
          <span
            className={`${
              completed ? 'bg-primary' : 'bg-lightGray'
            } w-[24px] h-[24px] rounded-full flex items-center justify-center`}
          >
            {completed ? (
              <IoCheckmark size={20} className="text-white" />
            ) : (
              <span className="pmi-caption">{index}</span>
            )}
          </span>
          <span className="">{title}</span>
        </h2>
        {active && headerActiveContent && <>{headerActiveContent}</>}
        {completed && onEdit && (
          <button
            onClick={
              editOnDetails
                ? isEditingDetails
                  ? stopEditingDetails
                  : startEditingDetails
                : onEdit
            }
            className="flex flex-row flex-nowrap gap-xs items-center w-fit text-primary border border-transparent hover:text-active hover:border-active hover:bg-secondary hover:rounded-full px-sm py-xs transition-all"
          >
            {isEditingDetails ? null : <IoPencilSharp size={16} />}
            {isEditingDetails ? t('Done') : t('Edit')}
          </button>
        )}
      </div>

      {active ? (
        <div className="p-sm border-t border-lightGray">{children}</div>
      ) : (
        <div className="px-sm pb-sm">
          <StepDetails step={step} editInDetails={isEditingDetails} />
        </div>
      )}
    </div>
  );
}

function StepDetails({
  step,
  editInDetails
}: {
  readonly step: WizardStepId;
  readonly editInDetails?: boolean;
}) {
  const { t } = useTranslation();
  const { userId, prospectId, articles, dispatchStepEvent } =
    useNewBasketContext();
  const basketOwnerId = userId ?? prospectId ?? undefined;

  if (step === 'owner' && basketOwnerId) {
    return (
      <BasketOwner
        basketOwnerId={basketOwnerId}
        basketCreadtedOn={Timestamp.now()}
      />
    );
  }

  if (step === 'content' && articles.size > 0) {
    return (
      <>
        <ProductsInBasketSummary allowEditing={editInDetails} small />
        {editInDetails && (
          <div className="flex justify-end mt-sm">
            <button
              className="hover:bg-lightGray rounded-full px-sm py-xs flex flex-row items-center gap-xs w-fit text-primary"
              onClick={() =>
                dispatchStepEvent({ type: 'edit-step', value: 'content' })
              }
            >
              <IoAddOutline size={16} />
              {t('Add more products')}
            </button>
          </div>
        )}
      </>
    );
  }

  return null;
}
