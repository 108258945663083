import PMIWeb from '@pmi.web/ui';
import { useTranslation } from 'libs/translations';
import { useCallback, useEffect, useRef, useState } from 'react';

const ONE_SECOND_IN_MS = 1000;

const REDIRECT_TIMER_IN_SECONDS = 5;
const PROGRESS_TICK = 100; // 0.1s
const PROGRESS_STEP =
  100 / REDIRECT_TIMER_IN_SECONDS / (ONE_SECOND_IN_MS / PROGRESS_TICK);
const TIMER_TICK = 1000; // 1s
const TIMER_STEP = 1;

interface IPrimaryLinkRedirectProps {
  readonly redirectUrl: string;
  readonly title: string;
  readonly onClick?: () => void;
}

export function PrimaryLinkRedirect(props: IPrimaryLinkRedirectProps) {
  const { t } = useTranslation();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [progress, setProgress] = useState<number>(0);
  const [timer, setTimer] = useState<number>(REDIRECT_TIMER_IN_SECONDS);

  const onClickWrapper = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [props]);

  useEffect(() => {
    window.setInterval(() => {
      setProgress(val => (val >= 100 ? val : val + PROGRESS_STEP));
    }, PROGRESS_TICK);

    window.setInterval(() => {
      setTimer(val => (val <= 0 ? val : val - TIMER_STEP));
    }, TIMER_TICK);
  }, []);

  useEffect(() => {
    if (progress >= 100 && linkRef && linkRef.current) {
      linkRef.current.click();
      onClickWrapper();
    }
  }, [onClickWrapper, progress, props]);

  return (
    <div className="flex flex-col gap-xs items-center justify-center">
      <div className="w-fit h-fit m-0 p-0 relative">
        <PMIWeb.Components.PrimaryLink
          ref={linkRef}
          href={props.redirectUrl}
          rel="noreferrer"
          target="_blank"
          onClick={onClickWrapper}
        >
          {props.title}
          <div
            className="bg-secondary rounded-button h-full absolute top-0 left-0 pointer-events-none opacity-10"
            style={{
              transition: '1s',
              width: `${progress}%`
            }}
          ></div>
        </PMIWeb.Components.PrimaryLink>
      </div>
      <p className="pmi-caption">
        {t('You will be redirected in {{seconds}} seconds', { seconds: timer })}
      </p>
    </div>
  );
}
