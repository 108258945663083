import { UserHeader, UserHeaderSkeleton } from 'components/headers';
import { Profile } from 'components/Profile';
import { useScreenEvents, useGlobalConfigsContext } from 'contexts';
import { ApiError } from 'errors';
import { DashboardLoader } from 'features/dashboard';
import { useDownlineContext } from 'features/downline-explorer/DownlineContext';
import { Icon, IconName } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchUserProfile } from 'services';
import { IUserProfile } from 'types';
import { decodeAsArray } from 'utils';

export function DownlineUserDashboardRoute() {
  const { historicalMonths, selectedPeriod } = useDownlineContext();
  const { t } = useTranslation();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();
  const { encodedUsersSelectedPath } = useParams();
  const { sm, md } = useScreenEvents();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [showUnavailableUserErrorMsg, setUnavailableUserErrorMsg] =
    useState<boolean>(false);
  const [isOpenUserProfile, setOpenUserProfile] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const [isBottomSheetContainerOpen, setBottomSheetContainerOpen] =
    useState<boolean>(false);

  const userId = useMemo(() => {
    if (_.isUndefined(encodedUsersSelectedPath)) {
      return undefined;
    }

    return decodeAsArray(encodedUsersSelectedPath).pop();
  }, [encodedUsersSelectedPath]);

  useEffect(() => {
    if (sm && isBottomSheetContainerOpen) {
      setBottomSheetContainerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sm]);

  useEffect(() => {
    if (_.isUndefined(userId)) {
      return;
    }

    setLoading(true);
    setUnavailableUserErrorMsg(false);
    setUserProfile(undefined);

    fetchUserProfile(userId)
      .then(userProfile => {
        setUserProfile(userProfile);
      })
      .catch(err => {
        if (
          err instanceof Error &&
          err.name === 'ApiError' &&
          (err as ApiError).status === 403
        ) {
          // Probably, this user is trying to access a country node which he cannot.
          // Show a nice message in the screen in this case.
          setUnavailableUserErrorMsg(true);
          return;
        }

        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  if (_.isUndefined(userId)) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="w-full animate-pulse space-y-8">
        <UserHeaderSkeleton />
      </div>
    );
  }

  if (showUnavailableUserErrorMsg) {
    return null;
  }

  if (_.isUndefined(userProfile)) {
    return null;
  }

  return (
    <section className="flex flex-col w-full">
      <header className="mb-sm">
        <div className="flex flex-row flex-wrap w-full items-center justify-between px-sm">
          <UserHeader
            user={userProfile}
            showBadges
            showQualification
            showCheckAssurance
            showNewRegistration
            disableNavigation
          />
          <button
            onClick={() => {
              trackEvent({
                name: 'DownlineUserProfileButtonClick'
              });
              setOpenUserProfile(true);
            }}
            className={`${
              supportsHover
                ? 'hover:text-active hover:bg-secondary'
                : 'text-primary underline'
            } transition-all px-sm py-xs -mx-sm -my-xs rounded-md hover:shadow-md`}
          >
            <Icon
              name={IconName.Badge}
              size={16}
              className="inline-block mr-xs"
            />
            {md && <>{t('User profile')}</>}
          </button>
        </div>
      </header>
      <Profile
        userId={userId}
        onDismiss={() => setOpenUserProfile(false)}
        open={isOpenUserProfile}
      />
      <DashboardLoader
        userId={userId}
        dashboardMonth={selectedPeriod}
        historicalMonths={historicalMonths}
      />
    </section>
  );
}
