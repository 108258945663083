import _ from 'lodash';
import { execGet } from 'services/RequestUtils';
import { IPromoShop } from 'types';

import { parseApiPromoShop } from './ShopHelper';
import { IApiPromoShop } from './types/IApiPromoShop';

export async function fetchPromoShop(locale?: string): Promise<IPromoShop> {
  const searchParams = new URLSearchParams();

  if (!_.isUndefined(locale)) {
    searchParams.set('locale', locale);
  }

  const endpoint = `${
    process.env.REACT_APP_SHOPS_API
  }/promotions?${searchParams.toString()}`;

  const response = await execGet<IApiPromoShop>(endpoint);

  return parseApiPromoShop(response);
}
