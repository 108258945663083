import PMIWeb from '@pmi.web/ui';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import { ICommitment } from 'types';

import { CommimentCard } from './CommimentCard';

export function PaymentRequiredCommitmentCard(commitment: ICommitment) {
  const { t } = useTranslation();

  return (
    <CommimentCard status={commitment.status}>
      <div className="flex flex-row flex-wrap gap-sm bg-orange-200 p-xs rounded-md justify-between items-center">
        <div>
          <h2 className="font-bold text-warn">
            {t('Your action is required')}
          </h2>
          <p className="text-warn">
            {t(
              'To fulfill your commitment and dispatch the products to you, the associated order must be paid.'
            )}
          </p>
        </div>
        <PMIWeb.Components.PrimaryLink
          target="_blank"
          rel="noreferrer"
          href={`${process.env.REACT_APP_MINI_SHOP_BASE_URL}/vcart/${commitment.fulfillmentBasketId}`}
          onClick={event => {
            trackEvent({
              name: 'VirtualWarehouse_CommitmentPayNow'
            });
            event.stopPropagation();
          }}
        >
          {t('Pay now')}
        </PMIWeb.Components.PrimaryLink>
      </div>
    </CommimentCard>
  );
}
