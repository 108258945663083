import { useEffect, useState } from 'react';
import { getProspectFormSchema } from 'services';

export function useCustomerRegistrationSchema(countryId: string) {
  const [schemas, setSchemas] = useState<{ uiSchema: any; schema: any }>();

  useEffect(() => {
    getProspectFormSchema(countryId).then(setSchemas);
  }, [countryId]);

  return schemas;
}
