import { type IBusinessLogicError, JSONForm } from '@pmi.web/registration';
import PMIWeb from '@pmi.web/ui';
import { UserHeader } from 'components/headers';
import { Pagination } from 'components/pagination';
import { LoadingSpinner } from 'components/spinners';
import { ApiError } from 'errors';
import { useUsersSearch } from 'features/analytics/hooks/useUsersSearch';
import { useCustomerRegistrationSchema } from 'features/baskets';
import { useBoolean } from 'hooks';
import { Icons, Icon, IconName } from 'libs/icons';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { IoPersonAddOutline } from 'react-icons/io5';
import { IUserSearchParamsV2, createProspect, fetchUser } from 'services';
import AuthenticationService from 'services/AuthenticationService';
import { IProspectCreationProps } from 'types';

import './BasketOwnerSelection.scss';
import { useNewBasketContext } from './NewBasketContext';

const DEFAULT_PAGE_SIZE = 5;
const DEBOUNCE_WAIT = 500;
const EMPTY_SEARCH_VALUE = '   ';

interface UserSearchParamsState extends IUserSearchParamsV2 {
  readonly pageNumber: number;
}

type UserSearchParamsStateAction =
  | {
      type: 'set-search';
      value: string | undefined;
    }
  | {
      type: 'change-page';
      value: number;
    };

function reducer(
  state: UserSearchParamsState,
  action: UserSearchParamsStateAction
): UserSearchParamsState {
  if (action.type === 'set-search') {
    return {
      ...state,
      search: action.value ?? EMPTY_SEARCH_VALUE,
      pageNumber: action.value !== state.search ? 1 : state.pageNumber
    };
  }

  if (action.type === 'change-page') {
    return {
      ...state,
      pageNumber: action.value
    };
  }

  return state;
}

export function BasketOwnerSelection() {
  const defaultUserSearchParams: UserSearchParamsState = {
    pageNumber: 1,
    search: EMPTY_SEARCH_VALUE,
    byCountry: AuthenticationService.user.countryCode,
    endCustomers: true,
    byDepthLevelTo: 1
  };

  const { t } = useTranslation();
  const { userId, prospectId, dispatchStepEvent } = useNewBasketContext();

  const [
    createNewCustomer,
    { setFalse: showExistingUserSelection, setTrue: showNewCustomerForm }
  ] = useBoolean(prospectId !== undefined ? true : false);

  const [userSearchParams, updateUserSearchParams] = useReducer(
    reducer,
    defaultUserSearchParams
  );

  const { register, handleSubmit, watch, setValue } = useForm<{
    readonly userId?: string;
    readonly search?: string;
  }>();

  const { data: searchResults, isLoading } = useUsersSearch(
    userSearchParams.pageNumber,
    DEFAULT_PAGE_SIZE,
    userSearchParams
  );

  const onSearchInputChanges = useMemo(
    () =>
      _.debounce(
        (searchInput?: string) =>
          updateUserSearchParams({ type: 'set-search', value: searchInput }),
        DEBOUNCE_WAIT
      ),
    []
  );

  const searchPlaceholder = useMemo(() => {
    let randomUser;

    if (searchResults && searchResults.data.length > 0) {
      randomUser =
        searchResults.data[
          Math.floor(Math.random() * searchResults.data.length)
        ];
    } else {
      return t('Search by name or Customer ID in your firstline');
    }

    const tpid = randomUser.userId;
    const name = randomUser.name;

    return Math.round(Math.random()) === 0 ? name : tpid;
  }, [searchResults, t]);

  const onSumbitHandler: SubmitHandler<{ readonly search?: string }> =
    useCallback(
      data => {
        onSearchInputChanges(data.search);
      },
      [onSearchInputChanges]
    );

  useEffect(() => {
    const subscription = watch(data => {
      onSearchInputChanges(data.search);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [onSearchInputChanges, watch]);

  useEffect(() => {
    if (userId) {
      updateUserSearchParams({ type: 'set-search', value: userId });
      setValue('search', userId);
    }
  }, []);

  if (createNewCustomer) {
    return (
      <>
        <button
          className="flex flex-row gap-xs items-center py-xs text-primary mb-md"
          onClick={showExistingUserSelection}
        >
          <Icons.Common.ChevronLeft />
          <span>{t('Choose existing customer instead')}</span>
        </button>
        <SelectNewCustomer />
      </>
    );
  }

  return (
    <>
      <div className="w-full flex">
        <form onSubmit={handleSubmit(onSumbitHandler)} className="flex-1">
          <label className="border border-silver rounded-md flex flex-nowrap items-center">
            <Icon
              name={IconName.Search}
              size={20}
              className="text-silver p-xs"
            />
            <input
              placeholder={searchPlaceholder}
              className="rounded-md flex flex-row items-center italic pr-xs py-xs w-full outline-none bg-white"
              type="search"
              {...register('search')}
            />
          </label>
        </form>
      </div>

      {isLoading && (
        <div className="p-sm flex items-center justify-center w-full min-h-[100px]">
          <LoadingSpinner smallSize />
        </div>
      )}

      {!isLoading &&
        !_.isUndefined(searchResults) &&
        searchResults.data.length > 0 && (
          <>
            <div className="flex w-full justify-end mt-md">
              <Pagination
                currentPage={searchResults.pageNumber}
                onPageChange={p =>
                  updateUserSearchParams({ type: 'change-page', value: p })
                }
                totalPages={searchResults.totalPages}
              />
            </div>

            <form id="comp__user-selection-form" className="mt-xs">
              {searchResults.data.map((searchResult, index) => (
                <label
                  className={`radio-option ${
                    userId === searchResult.userId ? 'selected' : ''
                  }`}
                  key={`radio__sr__${searchResult.userId}_${index}`}
                >
                  <input
                    type="radio"
                    {...register('userId', {
                      onChange: handleSubmit(data => {
                        if (data.userId) {
                          dispatchStepEvent({
                            type: 'set-userid',
                            value: data.userId
                          });
                        }
                      })
                    })}
                    value={searchResult.userId}
                  />
                  <div>
                    <UserHeader
                      user={searchResult}
                      showBadges
                      showCheckAssurance
                      showQualification
                      showNewRegistration
                      disableNavigation={true}
                    />
                  </div>
                </label>
              ))}
            </form>
          </>
        )}

      {!isLoading &&
        !_.isUndefined(searchResults) &&
        searchResults.data.length === 0 && (
          <div className="text-silver flex flex-col items-center gap-xs mt-md">
            <div className="flex items-center justify-center gap-xs">
              <Icon name={IconName.Search} size={24} />
              <p>{t('No results found')}</p>
            </div>
            <p className="text-center">
              {t("Please make sure you don't have any typos.")}
            </p>
          </div>
        )}

      <div className="flex justify-end gap-sm w-full mt-md sticky bg-white bottom-0 py-xs bg-opacity-90 -px-sm">
        <button
          className="flex flex-row flex-nowrap gap-xs items-center w-fit text-primary border border-transparent hover:text-active hover:border-active hover:bg-secondary hover:rounded-full px-sm py-xs transition-all"
          onClick={showNewCustomerForm}
        >
          <IoPersonAddOutline size={20} />
          <span>{t('New Customer')}</span>
        </button>
        <PMIWeb.Components.PrimaryButton
          disabled={userId === undefined}
          onClick={() => {
            if (userId) {
              dispatchStepEvent({
                type: 'confirm-userid'
              });
            }
          }}
        >
          {t('Continue')}
        </PMIWeb.Components.PrimaryButton>
      </div>
    </>
  );
}

function SelectNewCustomer() {
  const { countryCode, userId: sponsorId } = AuthenticationService.user;
  const schemas = useCustomerRegistrationSchema(countryCode);
  const { t, activeLanguage } = useTranslation();

  const { dispatchStepEvent, prospectId } = useNewBasketContext();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [prospectData, setProspectData] = useState<
    IProspectCreationProps | undefined
  >();
  const [businessLogicErrors, setBusinessLogicErrors] = useState<
    IBusinessLogicError[]
  >([]);

  const [formHasErrors, setFormHasErrors] = useState<boolean>(false);

  const onApiError = (err: ApiError) => {
    if (err.message?.includes('Validation failed')) {
      const businessLogicErrorsL: IBusinessLogicError[] = [];

      if (err.errors?.email?.includes('email is taken')) {
        businessLogicErrorsL.push({
          field: 'email',
          message: t('Email address is already in use')
        });
      }

      if (err.errors?.dateOfBirth?.includes('18')) {
        businessLogicErrorsL.push({
          field: 'dateOfBirth',
          message: t('Customer must be at least 18 years old.')
        });
      }

      if (err.errors?.email?.includes('Invalid Tax Id')) {
        businessLogicErrorsL.push({
          field: 'taxId',
          message: t('Invalid Tax Id')
        });
      }

      setBusinessLogicErrors([...businessLogicErrorsL]);
      return;
    }

    triggerErrorToast(`Something went wrong. Error: ${err.message}`);
  };

  const onError = (err: Error) => {
    triggerErrorToast(`Something went wrong. Error: ${err.message}`);
  };

  const onProspectDataChange = (
    newProspectData: IProspectCreationProps,
    errors: unknown[]
  ) => {
    setFormHasErrors(errors?.length > 0);
    setProspectData(newProspectData);
  };

  const onSaveProspect = async () => {
    if (!prospectData || formHasErrors) {
      return;
    }

    const prospectCreationProps: IProspectCreationProps = {
      ...prospectData,
      countryCode,
      sponsorId
    };

    setLoading(true);
    try {
      let prospectId = undefined;
      try {
        prospectId = await createProspect(prospectCreationProps);
      } catch (e) {
        onApiError(e as ApiError);
        return;
      }

      if (!prospectId) {
        onError(new Error('Prospect was not created successfully'));
        return;
      }

      dispatchStepEvent({
        type: 'set-prospectid',
        value: prospectId
      });
    } catch (err) {
      onError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (prospectId) {
      setLoading(true);
      fetchUser(prospectId)
        .then(apiData =>
          setProspectData({
            countryCode,
            sponsorId: apiData.sponsorId,
            familyName: apiData.familyName,
            givenName: apiData.givenName,
            salutation: apiData.salutation,
            email: apiData.emails[0]?.email,
            address: apiData.addresses[0],
            dateOfBirth: apiData.dateOfBirth?.rawValue,
            phoneNumber: apiData.phoneNumbers[0]?.number
          })
        )
        .finally(() => setLoading(false));
    }
  }, [countryCode, prospectId]);

  if (!schemas) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="w-full flex justify-center p-md">
        <LoadingSpinner smallSize />
      </div>
    );
  }

  return (
    <>
      <div className="px-xs">
        <JSONForm
          defaultFormData={prospectData ?? {}}
          country={countryCode}
          schema={schemas.schema}
          uiSchema={schemas.uiSchema}
          locale={activeLanguage}
          businessLogicErrors={businessLogicErrors}
          onDataChange={onProspectDataChange}
        />
      </div>
      {isLoading && (
        <div className="p-sm flex items-center justify-center w-full min-h-[100px]">
          <LoadingSpinner smallSize />
        </div>
      )}
      <div className="flex justify-end mt-sm sticky bottom-0 bg-white py-xs bg-opacity-90">
        <PMIWeb.Components.PrimaryButton
          onClick={onSaveProspect}
          disabled={!prospectData}
        >
          {t('Save')}
          <Icons.Common.ChevronRight />
        </PMIWeb.Components.PrimaryButton>
      </div>
    </>
  );
}
