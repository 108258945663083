import { useTranslation } from 'libs/translations';
import { useLocation, useMatch } from 'react-router-dom';

export function usePageTitle() {
  const { t } = useTranslation();
  const location = useLocation();
  const match = useMatch(location.pathname);

  function getRouteTitle(pathname: string): string | undefined {
    if (pathname === '/dashboard') {
      return t('Dashboard');
    }

    if (pathname === '/analytics') {
      return t('Analytics');
    }

    if (
      pathname === '/virtual-warehouse' ||
      pathname.startsWith('/virtual-warehouse/')
    ) {
      return t('Virtual Warehouse');
    }

    if (pathname === '/downline' || pathname.startsWith('/downline/')) {
      return t('My Team');
    }

    if (pathname === '/events' || pathname.startsWith('/events/')) {
      return t('Events');
    }

    if (pathname === '/account/documents') {
      return t('My documents');
    }

    if (pathname === '/account/language') {
      return t('Language');
    }

    if (pathname === '/account/payouts') {
      return t('Payouts');
    }

    if (pathname === '/account/profile') {
      return t('Profile');
    }

    if (
      pathname === '/account/orders' ||
      pathname.startsWith('/account/orders')
    ) {
      return t('My Orders');
    }

    if (pathname === '/account/app-settings') {
      return t('General settings');
    }

    if (pathname === '/account/pmcredit') {
      return t('PM Credit');
    }

    if (pathname === '/account') {
      return t('Account');
    }

    if (pathname === '/whatsnew') {
      return t("What's New");
    }

    if (pathname === '/sales/createbasket') {
      return t('Create a shared basket');
    }

    if (pathname.startsWith('/sales')) {
      return t('Share Baskets');
    }

    if (pathname.startsWith('/promocode')) {
      return t('Customer Promo Code');
    }
  }

  const title = getRouteTitle(match?.pathname ?? '');

  return { title, getRouteTitle };
}
