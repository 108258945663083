import { Timestamp } from 'libs/time';
import {
  IOrder,
  OrderChannel,
  OrderDeliveryType,
  OrderStatus
} from 'types/orders';
import { IOrderDeliveryPackage } from 'types/orders/IOrderDelivery';

import { mapOrderAddress } from './OrderAddressMapper';
import { mapOrderItem } from './OrderItemMapper';
import { mapOrderPayment } from './OrderPaymentMapper';
import { mapOrderSource } from './OrderSourceMapper';
import { TRACKING_SERVICES } from '../helpers/TrackingServices';
import { IOrderApi, IOrderDeliveryApi } from '../types';

export function mapAPIOrder(apiOrder: IOrderApi): IOrder {
  const {
    channel,
    createdOn,
    customerId,
    delivery,
    fulfillmentStatus,
    id,
    items,
    legalEntityNumber,
    mandator,
    payment,
    priority,
    reference,
    shopId,
    status,
    totals,
    billing,
    comment,
    commissionPeriodDate,
    createdBy,
    customer,
    discount = [],
    localeCode,
    qrCodeUri,
    shopUrl,
    subscriptionInfo,
    updatedBy,
    updatedOn
  } = apiOrder;

  return {
    channel,
    customerId,
    fulfillmentStatus,
    id,
    legalEntityNumber,
    mandator,
    priority,
    reference,
    shopId,
    totals,
    comment,
    commissionPeriodDate,
    createdBy,
    customer,
    discount,
    localeCode,
    qrCodeUri,
    shopUrl,
    subscriptionInfo,
    updatedBy,
    updatedOn,

    createdOn: Timestamp.createOrThrow(createdOn),
    items: items.map(mapOrderItem),
    status: status.toLowerCase() as OrderStatus,
    payment: mapOrderPayment(payment),
    delivery: {
      selected: {
        ...delivery.selected,
        type: delivery.selected.type as OrderDeliveryType
      },
      address: mapOrderAddress(delivery.address),
      packages: parsePackages(delivery),
      pickupNumber: delivery.pickupNumber
    },
    billing: billing
      ? {
          ...billing,
          address: mapOrderAddress(billing.address)
        }
      : undefined,
    source: mapOrderSource(
      channel.toLowerCase() as OrderChannel,
      subscriptionInfo
    )
  };
}

function parsePackages(
  delivery: IOrderDeliveryApi
): ReadonlyArray<IOrderDeliveryPackage> | undefined {
  if (!delivery.packages || delivery.packages.length === 0) {
    return undefined;
  }

  const { provider } = delivery.selected;
  const urlGenerator = provider
    ? TRACKING_SERVICES[provider]?.computeTrackingUrl
    : undefined;

  return delivery.packages.map(p => {
    return { id: p.id, url: urlGenerator ? urlGenerator(p.id) : undefined };
  });
}
