import { IS_PROD_BUILD } from 'contants';
import { trackException } from 'libs/telemetry';
import { Timestamp } from 'libs/time';
// eslint-disable-next-line no-restricted-imports
import { ISbStoriesParams } from 'storyblok-js-client';

import { IChangelog } from './IChangelog';
import {
  IStoryblokChangelogStory,
  IStoryblokChangelogStoryItem
} from './IStoryblokChangelogStory';
import {
  mapLanguageToStoryblokLocale,
  parseStoryblockDate,
  parseStoryblockDatetime
} from '../StoryblockUtils';
import { STORYBLOK_CLIENT } from '../Storyblok';

export async function fetchChangelogStory(
  slug: string,
  language: string
): Promise<ReadonlyArray<IChangelog>> {
  const storyblokParams: ISbStoriesParams = {
    version: IS_PROD_BUILD ? 'published' : 'draft',
    resolve_relations: ['featured-posts.posts', 'selected-posts.posts'],
    language: mapLanguageToStoryblokLocale(language),
    token: `${process.env.REACT_APP_STORYBLOK_OFFICE_ACCESS_TOKEN}`
  };

  const response = (
    await STORYBLOK_CLIENT.get(`cdn/stories/${slug}`, storyblokParams)
  )?.data?.story as IStoryblokChangelogStory;

  return response.content.items
    .map(parseStoryblokChangelog)
    .filter(items => items !== undefined) as ReadonlyArray<IChangelog>;
}

function parseStoryblokChangelog(
  changelog: IStoryblokChangelogStoryItem
): IChangelog | undefined {
  try {
    return {
      _uid: changelog._uid,
      title: changelog.title,
      description: changelog.description ? changelog.description : undefined,
      tags: changelog.tags ?? [],
      features: changelog.features ?? [],
      date: Timestamp.createOrThrow(parseStoryblockDate(changelog.date)),
      dateFrom: changelog.dateFrom
        ? Timestamp.createOrThrow(parseStoryblockDatetime(changelog.dateFrom))
        : undefined,
      dateTo: changelog.dateTo
        ? Timestamp.createOrThrow(parseStoryblockDatetime(changelog.dateTo))
        : undefined,
      previewUrl: changelog.preview.filename
    };
  } catch (err) {
    trackException(err, 'ChangelogStoryblok.ts', 'Failed to parse item', {
      changelog
    });
    return undefined;
  }
}
