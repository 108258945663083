export enum FeatureIconName {
  CheckAssurance,
  Qualification,
  PreviousQualification,
  NextQualification,
  Rank,
  PersonalSales,
  GroupSales,
  SixLevelSales,
  StructureSales,
  Registrations,
  Payouts,
  Autoships,
  TeamQualifications,
  CarBonus,
  Firstline,
  PrivacyGuard,
  FitlineClubMember
}

export enum RouteIconName {
  Dashboard,
  MyTeam,
  Analytics,
  Events,
  VirtualWarehouse,
  Account,
  StructureAnalyzer,
  WhatsNew,
  Sales,
  Promocode
}

export enum IconName {
  // General Purpose Icons
  ShoppingCart,
  ShoppingBag,
  ShoppingCartWithPlusSign,
  Share,
  Search,
  Copy,
  TrashCan,
  Checkmark,
  Video,
  Megaphone,

  Person,

  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,

  MinusSign,
  PlusSign,

  Warning,
  Info,

  // COMPATIBILITY
  Add,
  ArrowDown,
  ArrowRight,
  ArrowUp,
  Badge,
  Ban,
  BarChart,
  Box,
  Basket,
  Briefcase,
  CalendarBack,
  Calendar,
  Call,
  Car,
  CheckMark,
  CheckPayment,
  Clock,
  Close,
  Coach,
  Coins,
  CreditCard,
  Cross,
  Documents,
  Email,
  Exit,
  FitLine,
  Flag,
  Gear,
  Groups,
  HighPriority,
  Key,
  Link,
  Language,
  Location,
  Menu,
  MoneyTransfer,
  Network,
  ParentAndChild,
  Percentage,
  PM,
  QRCode,
  Seat,
  SixCircle,
  Star,
  Ticket,
  Shield,
  EyeShow,
  EyeHide,
  Scan,
  Filter,
  Sort,
  SimpleArrowUp,
  SimpleArrowDown,
  Receipt
}
