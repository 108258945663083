import { Fragment } from 'react/jsx-runtime';
// eslint-disable-next-line no-restricted-imports
import { IoCheckmark } from 'react-icons/io5';

import { IWizardProgressTrackerProps } from './IWizardProgressTrackerProps';
import { useStepI18n } from './useStepI18n';
import { WizardStepIcon } from './WizardStepIcon';

export function WizardHorizontalProgressTracker(
  props: IWizardProgressTrackerProps
) {
  const tStep = useStepI18n();
  const selectedIndex = props.availableSteps.findIndex(
    step => step === props.activeStep
  );

  return (
    <div className="flex flex-row items-center justify-center gap-xs w-full">
      {props.availableSteps.map((step, index) => (
        <Fragment key={step}>
          <div className="flex flex-row items-center gap-xs">
            {index === selectedIndex ? (
              <>
                <p className="pmi-caption text-center line-clamp-1 hidden md:block">
                  {index + 1}. {tStep(step)}
                </p>
                <span className="block md:hidden text-active">
                  <WizardStepIcon step={step} />
                </span>
              </>
            ) : index < selectedIndex ? (
              <IoCheckmark
                size={16}
                className={`${
                  index < selectedIndex ? 'bg-primary' : 'bg-lightGray'
                } w-[20px] h-[20px] rounded-full text-white`}
              />
            ) : (
              <div
                className={`bg-lightGray w-[20px] h-[20px] rounded-full text-white`}
              ></div>
            )}
          </div>
          {index < props.availableSteps.length - 1 && (
            <span
              className={`border-b-2 min-w-[16px] flex-1 ${
                index < selectedIndex ? 'border-primary' : 'border-lightGray'
              }`}
            ></span>
          )}
        </Fragment>
      ))}
    </div>
  );
}
