import { parseSupportedLanguage } from '@pmi.web/localization';
import { Timestamp } from 'libs/time';
import _ from 'lodash';
import {
  IMeAreaPreferences,
  IOfficeUserPreferences,
  IUserServerState
} from 'types';
import { parseBoolean } from 'utils';

import { execGet, execPatch, execPost } from '../RequestUtils';

interface IUserServerStateApi {
  readonly settings: {
    readonly me?: {
      readonly preferedLanguage?: string;
    };
    readonly office?: {
      readonly enableOptionalDiagnostics?: boolean;
      readonly enableAnimations?: boolean;
      readonly collapseNavBar?: boolean;
      readonly lastCheckAssuranceAchivementDate?: string;
      readonly lastBonusPayoutUpdateDate?: string;
      readonly welcomeMessageDisplayDate?: string;
      readonly welcomeVirtualWarehouseDisplayDate?: string;
      readonly notificationsSeen?: ReadonlyArray<string>;
    };
  };
}

export async function fetchPreferences(): Promise<IUserServerState> {
  const userServerState = await execGet<IUserServerStateApi>(
    `${process.env.REACT_APP_PROFILE_API}/all`
  );

  if (_.isUndefined(userServerState.settings.me)) {
    await execPost<{ me: IMeAreaPreferences }>(
      `${process.env.REACT_APP_PROFILE_API}/me`,
      {}
    );
  }

  if (_.isUndefined(userServerState.settings.office)) {
    await execPost<{ office: IOfficeUserPreferences }>(
      `${process.env.REACT_APP_PROFILE_API}/office`,
      {}
    );
  }

  return parseUserServerState(userServerState);
}

function parseUserServerState(
  serverState: IUserServerStateApi
): IUserServerState {
  const parsedServerState: IUserServerState = {
    me: {
      preferedLanguage: serverState.settings.me?.preferedLanguage
        ? parseSupportedLanguage(serverState.settings.me.preferedLanguage)
        : undefined
    },
    office: {
      collapseNavBar: parseBoolean(serverState.settings.office?.collapseNavBar),
      enableAnimations: parseBoolean(
        serverState.settings.office?.enableAnimations
      ),
      enableOptionalDiagnostics: parseBoolean(
        serverState.settings.office?.enableOptionalDiagnostics
      ),
      notificationsSeen: parseArrayOfStrings(
        serverState.settings.office?.notificationsSeen
      ),
      lastBonusPayoutUpdateDate:
        serverState.settings.office?.lastBonusPayoutUpdateDate &&
        isValidISOStringDate(
          serverState.settings.office.lastBonusPayoutUpdateDate
        )
          ? Timestamp.create(
              serverState.settings.office.lastBonusPayoutUpdateDate
            )
          : undefined,
      lastCheckAssuranceAchivementDate:
        serverState.settings.office?.lastCheckAssuranceAchivementDate &&
        isValidISOStringDate(
          serverState.settings.office.lastCheckAssuranceAchivementDate
        )
          ? Timestamp.create(
              serverState.settings.office.lastCheckAssuranceAchivementDate
            )
          : undefined,
      welcomeMessageDisplayDate:
        serverState.settings.office?.welcomeMessageDisplayDate &&
        isValidISOStringDate(
          serverState.settings.office.welcomeMessageDisplayDate
        )
          ? Timestamp.create(
              serverState.settings.office.welcomeMessageDisplayDate
            )
          : undefined,
      welcomeVirtualWarehouseDisplayDate:
        serverState.settings.office?.welcomeVirtualWarehouseDisplayDate &&
        isValidISOStringDate(
          serverState.settings.office.welcomeVirtualWarehouseDisplayDate
        )
          ? Timestamp.create(
              serverState.settings.office.welcomeVirtualWarehouseDisplayDate
            )
          : undefined
    }
  };

  return parsedServerState;
}

function isValidISOStringDate(date: unknown): boolean {
  return (
    !_.isUndefined(date) &&
    typeof date === 'string' &&
    !_.isUndefined(Timestamp.create(date))
  );
}

function parseArrayOfStrings(
  possibleArrayOfStrings: unknown
): ReadonlyArray<string> {
  if (!Array.isArray(possibleArrayOfStrings)) {
    return [];
  }

  return possibleArrayOfStrings.filter(v => typeof v === 'string');
}

export async function patchOfficePreferences(
  preferences: Partial<IOfficeUserPreferences>
): Promise<void> {
  return execPatch(`${process.env.REACT_APP_PROFILE_API}/office`, preferences);
}

export async function patchMePreferences(
  preferences: Partial<IMeAreaPreferences>
): Promise<void> {
  return execPatch(`${process.env.REACT_APP_PROFILE_API}/me`, preferences);
}
