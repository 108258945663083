import { Modal } from 'components/dialogs';
import { LoadingSpinner } from 'components/spinners';
import { useGlobalConfigsContext } from 'contexts';
import {
  DownlineEventTicketsExplorer,
  EventTicketsList
} from 'features/events';
import { useEvent } from 'features/events/hooks/useEvent';
import { useEventStory } from 'libs/cms';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  computeEventPreferedLanguage,
  getEventPageExternalURL,
  getEventRegistrationExternalURL
} from 'services';

export function EventDetailsRoute() {
  const { eventId } = useParams();

  if (_.isUndefined(eventId)) {
    return null;
  }

  return <EventDetailsPage eventId={eventId} />;
}

interface IEventDetailsPageProps {
  readonly eventId: string;
}

function EventDetailsPage(props: IEventDetailsPageProps) {
  const { t, activeLanguage } = useTranslation();

  const { data: eventConfig, isLoading: isEventConfigLoading } = useEvent(
    props.eventId
  );
  const {
    eventPreferredLanguage,
    eventRegistrationUrl,
    eventPublicPage,
    isOnlineEvent
  } = useMemo(() => {
    const eventPreferredLanguage = computeEventPreferedLanguage(
      eventConfig?.languages ?? [],
      activeLanguage
    );
    const eventRegistrationUrl = eventPreferredLanguage
      ? getEventRegistrationExternalURL(props.eventId, eventPreferredLanguage)
      : '';
    const eventPublicPage = eventPreferredLanguage
      ? getEventPageExternalURL(props.eventId, eventPreferredLanguage)
      : '';

    const isOnlineEvent = !eventConfig?.ticketSettings.items.find(
      t => t.type !== 'online'
    );

    return {
      eventPreferredLanguage,
      eventRegistrationUrl,
      eventPublicPage,
      isOnlineEvent
    };
  }, [
    eventConfig?.languages,
    eventConfig?.ticketSettings,
    activeLanguage,
    props.eventId
  ]);

  const { eventStory, isLoading: isEventStoryLoading } = useEventStory(
    props.eventId,
    eventConfig?.storyblokSlug,
    eventPreferredLanguage
  );

  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();

  if (isEventConfigLoading || isEventStoryLoading) {
    return (
      <section className="shadow-md bg-white rounded-md p-sm w-full h-full flex items-center justify-center">
        <LoadingSpinner smallSize />
      </section>
    );
  }

  if (_.isUndefined(eventConfig) || _.isUndefined(eventStory)) {
    return null;
  }

  return (
    <>
      <div className="bg-white p-sm rounded-md shadow-md">
        <section className="grid grid-cols-1 md:grid-cols-2 gap-sm">
          <div className="order-2 md:order-1">
            <p className="flex flex-row items-center text-primary gap-sm font-bold">
              {eventConfig.dates.countdown.toLocaleDateTimeString(
                activeLanguage,
                'medium'
              )}
            </p>
            <h1>
              {eventStory.title ?? eventStory.description ?? eventConfig.id}
            </h1>

            {eventStory.description && (
              <p className="mt-sm">{eventStory.description}</p>
            )}

            <div className="flex flex-row flex-wrap gap-sm mt-lg">
              <a
                href={eventRegistrationUrl}
                rel="noreferrer"
                target={`pmi_web_office__event_registration_${eventConfig.id}`}
                className={`${
                  supportsHover ? 'hover:brightness-110' : ''
                } px-sm py-xs text-white bg-primary rounded-full border border-primary flex flex-row items-center gap-xs w-fit h-fit text-center`}
              >
                <Icon name={IconName.Link} size={16} />
                {t('Get Tickets')}
              </a>
              <a
                href={eventPublicPage}
                rel="noreferrer"
                target={`pmi_web_office__event_page_${eventConfig.id}`}
                className={`${
                  supportsHover ? 'hover:text-active hover:border-active' : ''
                } rounded-full px-sm py-xs text-primary border border-primary flex flex-row items-center gap-xs w-fit h-fit text-center`}
              >
                <Icon name={IconName.Link} size={16} />
                {t('Open event page')}
              </a>
              {!_.isUndefined(eventConfig.seatingMapUrl) && (
                <Modal
                  triggerNode={
                    <div
                      className={`${
                        supportsHover ? 'hover:text-active' : ''
                      } underline text-primary flex flex-row items-center gap-xs w-fit h-fit`}
                    >
                      <Icon name={IconName.Seat} size={16} />
                      {t('Seat map')}
                    </div>
                  }
                >
                  <img src={eventConfig.seatingMapUrl} alt={t('Seat map')} />
                </Modal>
              )}
            </div>

            <div className="mt-lg">
              <div className="flex flex-row gap-xs items-baseline">
                <Icon name={IconName.Clock} size={16} />
                <div>
                  {eventConfig.dates.countdown.toLocaleDateTimeString(
                    activeLanguage,
                    'medium'
                  )}
                </div>
              </div>

              {(!_.isUndefined(eventStory.venue) ||
                !_.isUndefined(eventStory.address)) && (
                <div className="flex flex-row gap-xs mt-sm items-baseline">
                  <Icon name={IconName.Location} size={16} />
                  <div>
                    {!_.isUndefined(eventStory.venue) && (
                      <p>{eventStory.venue}</p>
                    )}
                    {!_.isUndefined(eventStory.address) && (
                      <p>{eventStory.address}</p>
                    )}
                  </div>
                </div>
              )}

              {isOnlineEvent && (
                <div className="flex flex-row gap-xs mt-sm">
                  <Icon name={IconName.Video} size={16} />
                  <div>
                    <p>{t('Online Event')}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="max-h-[300px] overflow-hidden order-1 md:order-2 rounded-md">
            {!_.isUndefined(eventStory.imgSrc) && (
              <img
                alt=""
                src={eventStory.imgSrc}
                className="w-full object-cover object-center"
              />
            )}
          </div>
        </section>

        <EventTicketsList
          eventId={props.eventId}
          ticketSettings={eventConfig.ticketSettings}
          eventName={eventStory.title ?? props.eventId}
        />
      </div>

      <DownlineEventTicketsExplorer eventId={props.eventId} />
    </>
  );
}
