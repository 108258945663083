import { IIconProps } from '../types/IIconProps';

export function HighestPriorityIcon(props: IIconProps) {
  return (
    <svg
      width={props.size}
      height={props.size}
      className={props.className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" stroke="currentColor" strokeWidth="1">
        <polyline
          fill="none"
          points="2 8 8 2 14 8"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          fill="none"
          points="2 14 8 8 14 14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
