import { SupportedLanguage } from '@pmi.web/localization';

export const LANGUAGE_NAMES_LOCALIZED = {
  [SupportedLanguage.Bulgarian]: 'български',
  [SupportedLanguage.Czech]: 'Čeština',
  [SupportedLanguage.German]: 'Deutsch',
  [SupportedLanguage.English]: 'English',
  [SupportedLanguage.Spanish]: 'Español',
  [SupportedLanguage.Finnish]: 'Suomi',
  [SupportedLanguage.French]: 'Français',
  [SupportedLanguage.Hungarian]: 'Magyar',
  [SupportedLanguage.Indonesian]: 'Indonesia',
  [SupportedLanguage.Icelandic]: 'Íslenska',
  [SupportedLanguage.Italian]: 'Italiano',
  [SupportedLanguage.Japanese]: '日本語',
  [SupportedLanguage.Korean]: '한국어',
  [SupportedLanguage.Norwegian]: 'Norsk bokmål',
  [SupportedLanguage.Dutch]: 'Nederlands',
  [SupportedLanguage.Polish]: 'Polski',
  [SupportedLanguage.Portuguese]: 'Português',
  [SupportedLanguage.Russian]: 'Pусский',
  [SupportedLanguage.Swedish]: 'Svenska',
  [SupportedLanguage.Thai]: 'ภาษาไทย',
  [SupportedLanguage.Turkish]: 'Türkçe',
  [SupportedLanguage.Ukrainian]: 'Українська',
  [SupportedLanguage.ChineseSimplified]: '中文',
  [SupportedLanguage.ChineseTraditional]: '中文',
  [SupportedLanguage.Vietnamese]: 'Tiếng Việt',
  [SupportedLanguage.Romanian]: 'Română'
};
