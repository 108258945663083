import { ErrorHandler } from 'components/errors/ErrorHandler';
import { LoadingSpinner } from 'components/spinners';
import { useTranslation } from 'libs/translations';
import { Fragment } from 'react/jsx-runtime';

import { FAQSection } from './FAQSection';
import { useVirtualWarehouseFAQs } from './useVirtualWarehouseFAQs';

export function FAQStoryblokContent() {
  const { activeLanguage } = useTranslation();
  const {
    data: faqs,
    isLoading,
    error
  } = useVirtualWarehouseFAQs(activeLanguage);

  return (
    <>
      {isLoading && (
        <div className="w-full flex justify-center p-md">
          <LoadingSpinner smallSize />
        </div>
      )}

      {!isLoading && error && (
        <div className="w-full min-h-[300px] flex items-center justify-center">
          <ErrorHandler err={error} />
        </div>
      )}

      {!isLoading && !error && (
        <Fragment>
          {faqs?.map(faq => (
            <FAQSection
              key={faq._uid}
              title={faq.question}
              content={faq.answer}
            />
          ))}
        </Fragment>
      )}
    </>
  );
}
