import { usePII } from 'features/pii';
import { Icon, IconName } from 'libs/icons';
import { getInitials } from 'utils';

import './UserAvatar.scss';

interface IAvatarProps {
  readonly userName: string;
  readonly huge?: boolean;
  readonly userType:
    | 'customer'
    | 'discount-customer'
    | 'teampartner'
    | 'prospect'
    | 'external-user';
}
export function UserAvatar(props: IAvatarProps) {
  const { pii } = usePII();

  return (
    <div
      className="comp__user-avatar"
      data-user-type={props.userType}
      data-user-size={props.huge ? 'big' : 'default'}
    >
      {props.userType === 'prospect' ? (
        <Icon name={IconName.Add} size={16} fill />
      ) : (
        getInitials(pii(props.userName, 'name'))
      )}
    </div>
  );
}

export function UserAvatarSkeleton() {
  return (
    <div
      className={`comp__user-avatar-skeleton w-avatar-sm h-avatar-sm `}
    ></div>
  );
}
