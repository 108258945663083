import { CountdownRenderer } from 'components/CountdownRenderer';
import { LabelledText } from 'components/text';
import { useTranslation } from 'libs/translations';
import Countdown from 'react-countdown';
import { ICommitment } from 'types';

import { CommimentCard } from './CommimentCard';

export function ActiveCommitmentCard(commitment: ICommitment) {
  const { t } = useTranslation();
  const { totalCommitedQuantity, totalFulfilledQuantity } =
    commitment.progress.reduce(
      (acc, value) => {
        return {
          totalCommitedQuantity:
            acc.totalCommitedQuantity + value.commitedQuantity,
          totalFulfilledQuantity:
            acc.totalFulfilledQuantity + value.fulfilledQuantity
        };
      },
      { totalCommitedQuantity: 0, totalFulfilledQuantity: 0 }
    );

  const totalUnfulfilledQuantity =
    totalCommitedQuantity - totalFulfilledQuantity;

  return (
    <CommimentCard status={commitment.status}>
      <div className="font-bold flex flex-row gap-md flex-wrap">
        <LabelledText
          label={t('Expires in')}
          value={
            <Countdown
              date={commitment.expiresOn.getTime()}
              renderer={p => (
                <div className={p.days > 0 ? 'min-w-[128px]' : 'min-w-[72px]'}>
                  <CountdownRenderer {...p} />
                </div>
              )}
            />
          }
        />
        {totalUnfulfilledQuantity > 0 && (
          <LabelledText
            label={t('Remaining')}
            value={
              totalUnfulfilledQuantity > 1
                ? t('{{numberOfProducts}} products', {
                    numberOfProducts: totalUnfulfilledQuantity
                  })
                : t('1 product')
            }
          />
        )}
      </div>
    </CommimentCard>
  );
}
