import { FormattedUnitValue } from 'components/text';
import {
  InvoiceCostItem,
  InvoiceCostTotal,
  InvoiceCosts,
  InvoiceSectionDivider
} from 'features/invoices';
import { useTranslation } from 'libs/translations';
import { IBasket } from 'types';

export function BasketCosts(props: IBasket) {
  const { t } = useTranslation();

  return (
    <InvoiceCosts>
      <InvoiceCostItem
        label={t('Subtotal')}
        value={
          <FormattedUnitValue
            unit={props.basket.totals.currencyCode}
            value={
              props.basket.totals.itemsGross -
              props.basket.totals.itemsTaxAmount
            }
          />
        }
      />
      <InvoiceCostItem
        label={t('Tax')}
        value={
          <FormattedUnitValue
            unit={props.basket.totals.currencyCode}
            value={props.basket.totals.totalTaxAmount}
          />
        }
      />
      {props.basket.totals.discountAmount !== 0 && (
        <InvoiceCostItem
          label={t('Discount')}
          value={
            <FormattedUnitValue
              unit={props.basket.totals.currencyCode}
              value={-1 * props.basket.totals.discountAmount}
            />
          }
        />
      )}
      <InvoiceSectionDivider thick />
      <InvoiceCostTotal
        label={t('Total')}
        value={
          <>
            {props.basket.totals.discountAmount !== 0 && (
              <span className="text-silver text-sm line-through mr-xxs block font-bold">
                <FormattedUnitValue
                  unit={props.basket.totals.currencyCode}
                  value={props.basket.totals.totalGrossExcludingDiscount}
                />
              </span>
            )}
            <span className="block font-bold text-xl">
              <FormattedUnitValue
                unit={props.basket.totals.currencyCode}
                value={props.basket.totals.totalGross}
              />
            </span>
            <span className="block pmi-caption text-silver">{`+ ${t(
              'Shipping if applicable'
            )}`}</span>
          </>
        }
      />
    </InvoiceCosts>
  );
}
