import { LoadingSpinner } from 'components/spinners';
import { useEventStory } from 'libs/cms';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { computeEventPreferedLanguage } from 'services';

import { useEvent } from './hooks/useEvent';

export function EventCard(props: { readonly eventId: string }) {
  const { t, activeLanguage } = useTranslation();
  const navigate = useNavigate();

  const { data: eventConfig, isLoading: isEventConfigLoading } = useEvent(
    props.eventId
  );
  const { eventStory, isLoading: isEventStoryLoading } = useEventStory(
    props.eventId,
    eventConfig?.storyblokSlug,
    computeEventPreferedLanguage(eventConfig?.languages ?? [], activeLanguage)
  );

  const isOnlineEvent = useMemo(() => {
    return !eventConfig?.ticketSettings.items.find(t => t.type !== 'online');
  }, [eventConfig]);

  if (isEventConfigLoading || isEventStoryLoading) {
    return (
      <section
        className="shadow-md rounded-md bg-white p-sm cursor-pointer hover:bg-secondary transition-all"
        onClick={() => {
          navigate(props.eventId);
        }}
      >
        <div className="w-full h-full flex items-center justify-center">
          <LoadingSpinner smallSize />
        </div>
      </section>
    );
  }

  if (_.isUndefined(eventStory) || _.isUndefined(eventConfig)) {
    return null;
  }

  return (
    <section
      className="shadow-md rounded-md bg-white p-sm cursor-pointer hover:bg-secondary transition-all"
      onClick={() => {
        navigate(props.eventId);
      }}
    >
      <div className="max-h-[150px] overflow-hidden rounded-md">
        {!_.isUndefined(eventStory.imgSrc) && (
          <img
            alt=""
            src={eventStory.imgSrc}
            className="w-full object-cover object-center"
          />
        )}
      </div>

      <h2 className="text-primary mt-sm">{eventStory.title}</h2>

      <div className="text-slate-500 flex flex-row items-center gap-xs mt-sm">
        <Icon
          name={IconName.Clock}
          size={14}
          className="w-[16px] h-[16px] m-[1px]"
        />
        <p className="pmi-caption">
          {eventConfig.dates.countdown.toLocaleDateString(activeLanguage)}
        </p>
        <p className="pmi-caption">
          {eventConfig.dates.countdown.toLocaleTimeString(activeLanguage)}
        </p>
      </div>

      {(!_.isUndefined(eventStory.venue) ||
        !_.isUndefined(eventStory.address)) && (
        <div className="text-slate-500 flex flex-row gap-xs mt-xs">
          <Icon name={IconName.Location} size={16} />
          <div>
            {!_.isUndefined(eventStory.venue) && (
              <p className="pmi-caption">{eventStory.venue}</p>
            )}
            {!_.isUndefined(eventStory.address) && (
              <p className="pmi-caption">{eventStory.address}</p>
            )}
          </div>
        </div>
      )}

      {isOnlineEvent && (
        <div className="text-slate-500 flex flex-row gap-xs mt-xs">
          <Icon name={IconName.Video} size={16} />
          <div>
            <p className="pmi-caption">{t('Online Event')}</p>
          </div>
        </div>
      )}
    </section>
  );
}
