import { triggerErrorToast, triggerSuccessToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import { useEffect } from 'react';

import { useCreatePromocode } from './useCreatePromocode';
import { useFetchPromocode } from './useFetchPromocode';

export function usePromocode() {
  const { t } = useTranslation();

  const { data: promocode, isPending, error } = useFetchPromocode();
  const {
    createPromocode,
    isPending: createPromocodePending,
    isError: createPromocodeHasError,
    isSuccess: createPromocodeSucceess
  } = useCreatePromocode();

  useEffect(() => {
    if (createPromocodeHasError) {
      triggerErrorToast(t('Couldn’t create customer promo code.'));
    }
  }, [createPromocodeHasError, t]);

  useEffect(() => {
    if (createPromocodeSucceess) {
      triggerSuccessToast(t('Customer promo code was created!'));
    }
  }, [t, createPromocodeSucceess]);

  return {
    promocode,
    error,
    isLoading: isPending || createPromocodePending,
    createPromocodeSucceess,
    createPromocode
  };
}
